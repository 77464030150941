@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4ec28t');
  src:  url('fonts/icomoon.eot?4ec28t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4ec28t') format('truetype'),
    url('fonts/icomoon.woff?4ec28t') format('woff'),
    url('fonts/icomoon.svg?4ec28t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fb_icon:before {
  content: "\e900";
}
.icon-insta_icon:before {
  content: "\e901";
}
.icon-pdfdl-icon:before {
  content: "\e902";
}
.icon-twitter_icon:before {
  content: "\e903";
}
.icon-play:before {
  content: "\e912";
}
