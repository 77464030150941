@charset "UTF-8";



/*******************************

    LOWER

*******************************/

.lower {
  position: relative;
  overflow: hidden;
  padding: 0 calc(10px + 2rem) 10rem;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 10px;
    background: #000;
    height: 100%;
    position: absolute;
    z-index: 99;
  }

  &::before {
    left: 0;
    top: 0;
  }

  &::after {
    right: 0;
    top: 0;
  }
}


.lower-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.lowerHeader {
  position: relative;
  margin: 7.5rem auto 11rem;
  max-width: 1200px;

  @include st-tblt {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  &_ttl {
    margin: 0 auto;
    text-align: center;
  }

  &-sns {
    display: flex;
    align-items: center;
    @include lt-tblt{
      top: 2.5rem;
    position: absolute;
    }
    @include st-tblt{
      margin-bottom: 3rem;
    }

    li {
      margin-right: 2rem;
      @include st-tblt{
        margin-right: 1rem;
      }
    }
    
    a{
      @include hover01;
      font-size: 18px;
    }
  }
}

.pager {
  .nav-links {
    display: flex;
    align-items: center;

    a,
    span {
      margin-right: 1rem;
    }

    a,
    .current {
      @include font_Din;
      border: none;
      width: 3rem;
      height: 3rem;
      text-decoration: none;
      font-size: 1.2rem;
      color: #000;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .current {
      background: #000;
      color: #fff;
    }

    a {
      @include hover01;
    }
  }
}


/*******************************

    ARCHIVE

*******************************/

.archive {
  &-ttl {
    margin-bottom: 10rem;

    @include st-tblt {
      margin-bottom: 3rem;
    }
  }

  &-inner {
    display: flex;
    align-items: flex-start;

    @include st-tblt {
      flex-direction: column;
    }
  }
}

.archiveMain {
  flex: 0 0 auto;

  @include lt-tblt {

    width: calc(80% - 10rem);
    margin-right: 10rem;
  }

  @include st-tblt {
    margin-bottom: 6rem;
  }

  &-list {
    @include block-2col($mr:10rem, $mt:10rem, $spcol:'2', $spmt:3rem);
    margin-bottom: 10rem;

    @include st-tblt {
      margin-bottom: 6rem;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;

      @include hover01;
    }

    figure {
      margin-bottom: 2rem;

      img {
        width: 100%;
      }
    }

    .txt {
      font-size: 1.3rem;
      line-height: 2.2;
      margin-bottom: 2.5rem;
    }

    .summary {
      @include font_Din;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      @include st-tblt{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .category {
      padding: .25rem 1.25rem .5rem;
      color: #fff;
      &-wrap{
        display: flex;
      flex-direction: column;
      align-items: flex-start;
        p:not(:last-of-type){
          margin-bottom: 0.5rem;
        }
      }
      //cat
      &.cat-cat01,
      &.cat-cat03 {
        background: #C3593F;
      }

      &.cat-cat02,
      &.cat-cat04 {
        background: #C3984F;
      }
    }

    .day {
      letter-spacing: 0.025em;
      @include st-tblt{
        order: -1;
        margin-bottom: 1rem;
      }
    }
  }
}

.archiveSide {
  width: 100%;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  padding: 4.5rem 0;

  &-ttl {
    text-align: center;
    margin-bottom: 5rem;
  }

  &-list {
    li {
      &:not(:last-of-type) {

        margin-bottom: 2rem;
      }
    }

    a{
      @include font_Din;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
      @include hover01;
    }

    li a::before {
      content: "";
      display: block;
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
    }

    li.cat-cat01 a::before{
      background: #C3593F;
    }

    li.cat-cat02 a::before{
      background: #C3984F;
    }
    li.cat-all a::before{
      background: #3C5F4A;
    }
  }
}

.eventArcMain{
  width: 100%;
}
.eventArc-list{
  li{
    margin-bottom: 10rem;
    @include st-tblt{
      margin-bottom: 4.5rem;
    }
  }
  li a{
    display: flex;
    align-items: center;
    width: 100%;
    @include hover01;
    @include st-tblt{
      flex-direction: column;
    }
  }

  .img{
    @include lt-tblt{
      
    width: 44.5%;
    }
    @include st-tblt{
      width: 100%;
      margin-bottom: 2rem;
    }
    img{
      width: 100%;
    }
  }
  .cont{
    @include lt-tblt{
      
    width: 55.5%;
    padding-left: 10rem;
    }
    @include st-tblt{
      width: 100%;
    }
    .day{
      @include font_Din;
      border-left: 3px solid #19507C;
      font-size: 2.5rem;
      text-align: center;
      display: inline-block;
      padding-left: 2rem;
      margin-bottom: 3.5rem;
      @include st-tblt{
        margin-bottom: 1rem;
      }
      strong{
        padding-top: .5rem;
        display: block;
        font-size: 3.5rem;
      }
    }
    .txt{
      width: 100%;
      font-size: 2rem;
      line-height: 2;
      margin-bottom:1.5rem;
    }
    .cat{
      margin-top: 3rem;
      span{
        @include font_Din;
        color: #00507C;
        font-size: 1.3rem;
        font-weight: 400;
        padding-right: 1.5rem;
      }
    }
    .subttl{
      font-size: 1.5rem;
      line-height: 1.5;
      @include st-tblt{
        line-height: 1.75;
      }
    }
  }
}
/*******************************

    SINGLE

*******************************/


.single {
  &-ttl {
    font-size: 2.5rem;
    line-height: 2;
    letter-spacing: .07em;
    
    @include st-tblt{
      line-height: 1.75;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -1.5rem;
    border-bottom: 3px solid #000;
    padding-bottom: .5rem;
    margin-bottom: 5rem;
    @include st-tblt{
      flex-direction: column;
    }

    .day {
      @include font_Din;
      font-size: 1.2rem;
      margin-bottom: 1.25rem;
      flex: 0 0 auto;
      padding-left: 3rem;
    }
  }

  &-inner {
    max-width: 800px;
    margin: 0 auto 9rem;
    
    @include st-tblt{
      margin-bottom: 6rem;
    }
  }

  &Thumb {
    margin-bottom: 4rem;
    text-align: center;
  }

  &Main {
    
    img{
      max-width: 100%;  
    }
    .single-cont{
      .ttl{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2em;
        line-height: 2;
      }
    p {
      line-height: 3;
      font-size: 1.3rem;
      margin-bottom: 4rem;
    }

    .img-1col {
      width: 100%;
    }

    .img-2col {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      img {
        display: block;
        max-width: 100%;
        width: calc(50% - 2rem);
        @include st-tblt{
          width: calc(50% - .5rem);
        }
      }
    }
  }
  }
  &-sns {
    display: flex;
    align-items: center;
    margin-bottom: 12rem;
    @include st-tblt{
      margin-bottom: 6rem;
    }
    li {
      margin-right: 2rem;
      
      @include st-tblt{
        margin-right: 1rem;
      }
    }
    a{
      @include hover01;
    }
  }
  &-event{
    flex: 0 0 auto;
    @include lt-tblt{
      
    width: calc(75% - 10rem);
    margin-right: 10rem;
    }
    &-wrap{
      display: flex;
      @include st-tblt{
        flex-direction: column;
      }
    }
  }
  &-info{
    border-top: 3px solid #000;
    width: 100%;
    margin-top: 1rem;
    padding-top: 5rem;
    &-ttl{
      text-align: center;
      margin-bottom: 5rem;
    }
    dl{
      margin-bottom: 4.5rem;
      div{
        display: flex;
        @include font_Din;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
      dt{
        width:11.5rem;
        color: rgba(#4D4D4D,.4);
      }
      dd{
        width:calc(100% - 11.5rem);
        font-weight: 400;
      }
    }
    &-btn{
      a{
        display: block;
        text-align: center;
        border: 3px solid #000;
        padding: 1.75rem .75rem;
        background: #C3984F;
        transition: $transition;
        &:hover{
          background: #3C5F4A;
        }
      }
    }
  }
}

.view-btn{
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  p{
    width: 11rem;
  }
  a{
    @include font_Din;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    @include hover01;
  }
  .next a{
    justify-content: flex-end;
  }
  
  .prev img{
    padding-right: 4rem;
    @include st-tblt{
      padding-right: 1rem;
    }
  }
  .next img{
    padding-left: 4rem;
    @include st-tblt{
      padding-left: 1rem;
    }
  }
}


//post
.singleMain{
  strong{
    font-weight: bold;
  }
  a{
    text-decoration: underline;
  }
  blockquote{
    padding: 1rem 2rem;
    background: rgba(77, 77, 77, 0.1);
    margin-bottom: 4rem;
    p:last-of-type{
      margin-bottom: 0;
    }
  }
}
