@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="button"], input[type="text"], input[type="tel"], input[type="email"], input[type="submit"], input[type="image"], textarea {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  background: #5f5f5f;
}

select::-ms-expand {
  display: none;
}

address {
  font-style: inherit;
}

strong {
  font-weight: normal;
}

/* MEDIA QUERY */
@media screen and (max-width: 641px) {
  .lt-sp {
    display: none !important;
  }
}

@media screen and (min-width: 642px) {
  .st-sp {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .lt-tblt {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .st-tblt {
    display: none !important;
  }
}

/* Text Color */
/* FONT SIZE */
/* FONT SET */
/* xd文字スタイルに入ってるセットを設定 */
/*******************************

   SINGLE

*******************************/
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?4ec28t");
  src: url("fonts/icomoon.eot?4ec28t#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?4ec28t") format("truetype"), url("fonts/icomoon.woff?4ec28t") format("woff"), url("fonts/icomoon.svg?4ec28t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fb_icon:before {
  content: "\e900";
}

.icon-insta_icon:before {
  content: "\e901";
}

.icon-pdfdl-icon:before {
  content: "\e902";
}

.icon-twitter_icon:before {
  content: "\e903";
}

.icon-play:before {
  content: "\e912";
}

html,
body {
  /* ルートのフォントサイズを10pxに設定しておく */
  font-size: 62.5%;
  font-family: a-otf-gothic-bbb-pr6n, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  transition: opacity ease 0.3s;
}

a:hover {
  opacity: 0.6;
}

img {
  max-width: 100%;
}

.of-cover {
  font-family: 'object-fit: cover;';
}

/* DISPLAY
------------------------------*/
@media screen and (min-width: 769px) {
  .lt-tbminNone {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .st-tbminNone {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .lt-tbltNone {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .st-tbltNone {
    display: none !important;
  }
}

@media screen and (min-width: 642px) {
  .lt-spNone {
    display: none !important;
  }
}

@media screen and (max-width: 641px) {
  .st-spNone {
    display: none !important;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .tbltNone {
    display: none !important;
  }
}

/*-----ここからheader-----*/
.head .header-inner .topborder {
  height: 10px;
  width: 100%;
  background-color: #000;
  position: fixed;
  z-index: 6;
}

.head .nav-copy {
  font-size: 7px;
  letter-spacing: 0.25rem;
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  z-index: 5;
  transform: rotatez(90deg) translateX(100%);
  transform-origin: right top;
  position: absolute;
  right: 53px;
  top: 175px;
}

@media screen and (max-width: 1024px) {
  .head .nav-copy {
    display: none;
  }
}

.scroll__container {
  position: absolute;
  z-index: 3;
  right: 16px;
  bottom: 100px;
}

@media screen and (max-width: 1024px) {
  .scroll__container {
    display: none;
  }
}

.scroll__container .scroll {
  position: relative;
  color: #000;
  height: 37px;
  padding: 2rem 0 0 1rem;
  writing-mode: vertical-rl;
}

.scroll__container .scroll .scroll-copy {
  font-size: 9px;
  /*font-family:'Noto Sans JP', sans-serif; ;*/
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-style: normal;
  position: absolute;
  right: 26px;
  bottom: 68px;
}

.scroll__container .scroll::before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 31px;
  background-color: #000;
  z-index: 10;
}

.scroll__container .scroll::after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  right: 28px;
  background-color: #000;
  top: 0;
  z-index: 9;
  border-radius: 100%;
}

.fixed-menu {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
}

.fixed-menu img {
  width: 150px;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .fixed-menu img {
    width: 7rem;
  }
}

.fixed-menu .ham {
  right: 90px;
  top: 30px;
  position: absolute;
  z-index: 3;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .fixed-menu .ham {
    right: 5rem;
    top: 0.5rem;
  }
}

.fixed-menu .ham_line {
  position: absolute;
  left: 10px;
  width: 43px;
  height: 2px;
  background-color: #ffffff;
  transition: all 0.4s;
}

@media screen and (max-width: 1024px) {
  .fixed-menu .ham_line {
    width: 3rem;
  }
}

.fixed-menu .ham_line1 {
  top: 10px;
}

.fixed-menu .ham_line2 {
  top: 18px;
}

.fixed-menu .ham_line3 {
  top: 26px;
}

.fixed-menu .clicked .ham_line1 {
  transform: rotate(20deg);
  top: 20px;
}

.fixed-menu .clicked .ham_line2 {
  width: 0px;
}

.fixed-menu .clicked .ham_line3 {
  transform: rotate(-20deg);
  top: 20px;
}

/*-----ここまでheader-----*/
/*-----ここからMAIN-----*/
.kv {
  border: solid 10px #000;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .kv {
    height: calc(100vh - 20px);
    min-height: 750px;
    max-height: 50vw;
  }
}

.kv .kv-inner {
  height: calc(100% - 100px);
}

.kv .kv-inner .kv-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.kv .kv-inner .kv-slider li {
  width: 100%;
  height: 100%;
  position: relative;
}

.kv .kv-inner .kv-slider li img {
  max-width: inherit;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .kv .kv-inner {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner {
    width: 100%;
  }
}

.kv .kv-inner .hand-copy {
  position: absolute;
}

@media screen and (min-width: 1025px) {
  .kv .kv-inner .hand-copy {
    bottom: 150px;
    right: 150px;
  }
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .hand-copy {
    bottom: calc(100px + 5vw);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .kv .kv-inner .hand-copy {
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .hand-copy img {
    width: 90%;
  }
}

.kv .kv-inner .toplogo {
  position: absolute;
  top: 30%;
  left: 100px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .toplogo {
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .kv .kv-inner .toplogo {
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

.kv .kv-inner .toplogo p {
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #fff;
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .toplogo p {
    margin-left: -0.8rem;
  }
}

.kv .kv-inner .toplogo img {
  width: 100px;
}

.kv .kv-inner .main-news {
  height: 100px;
  width: 400px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 50px;
}

.kv .kv-inner .main-news a {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .main-news {
    width: 100%;
    bottom: 0;
  }
}

.kv .kv-inner .main-news img {
  width: 100px;
  height: 100px;
}

.kv .kv-inner .main-news .main-news-copy {
  margin-left: 30px;
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .main-news .main-news-copy {
    margin-left: 1.5rem;
  }
}

.kv .kv-inner .main-news .main-news-copy .main-news-title {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1rem;
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .main-news .main-news-copy .main-news-title {
    margin-top: 3rem;
  }
}

.kv .kv-inner .main-news .main-news-copy .main-news-day {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
}

.kv .kv-inner .main-viewmore {
  position: absolute;
  font-size: 13px;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@media screen and (min-width: 1025px) {
  .kv .kv-inner .main-viewmore {
    bottom: 65px;
    left: 426px;
  }
}

@media screen and (max-width: 1024px) {
  .kv .kv-inner .main-viewmore {
    bottom: 1rem;
    right: 1rem;
  }
}

.kv .kv-inner .main-viewmore a {
  text-decoration: none;
  color: #000;
  display: block;
}

.kv .kv-inner .main-viewmore::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 6px;
  left: -42px;
}

.kv .slick-list.draggable,
.kv .slick-track {
  height: 100%;
}

.kv .kv-dots {
  position: absolute;
  bottom: -20px;
  right: 0;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .kv .kv-dots {
    bottom: 1rem;
    right: 1rem;
  }
}

.kv .kv-dots li {
  margin-left: 10px;
}

.kv .kv-dots .slick-active button {
  background: #000;
}

.kv .kv-dots button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #000;
  box-sizing: border-box;
  font-size: 0;
  background: #fff;
}

/*-----ここまでMAIN-----*/
/*-----ここからPICK UP-----*/
.pickup {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  padding: 0 1rem;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .pickup {
    height: auto;
  }
}

.pickup .pickup-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 9rem;
  padding-bottom: 10rem;
}

@media screen and (max-width: 1024px) {
  .pickup .pickup-inner {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.pickup .pickup-inner h2 {
  margin-bottom: 45px;
}

@media screen and (max-width: 1024px) {
  .pickup .pickup-inner h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
}

.pickup .pickup-inner .pickup-list {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {
  .pickup .pickup-inner .pickup-list li {
    width: calc(25% - ((5.3rem * 3) / 4));
  }
  .pickup .pickup-inner .pickup-list li:not(:nth-of-type(4n)) {
    margin-right: 5.3rem;
  }
  .pickup .pickup-inner .pickup-list li:nth-of-type(n+5) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .pickup .pickup-inner .pickup-list li {
    width: calc(50% - (5.3rem / 2));
  }
  .pickup .pickup-inner .pickup-list li:nth-of-type(odd) {
    margin-right: 5.3rem;
  }
  .pickup .pickup-inner .pickup-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .pickup .pickup-inner .pickup-list li {
    width: calc(50% - (1rem / 2));
  }
  .pickup .pickup-inner .pickup-list li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .pickup .pickup-inner .pickup-list li:nth-of-type(n+3) {
    margin-top: 2rem;
  }
}

.pickup .pickup-inner .pickup-list a {
  display: block;
}

.pickup .pickup-inner .pickup-list p {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  letter-spacing: 0.07em;
  line-height: 1.8;
}

@media screen and (max-width: 641px) {
  .pickup .pickup-inner .pickup-list p {
    font-size: 15px;
  }
}

/*-----ここまでPICK UP-----*/
/*-----ここからCONCEPT-----*/
.concept {
  background-image: url(../image/concept-bg.jpg);
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: lighten;
  background-size: cover;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

@media screen and (min-width: 1025px) {
  .concept {
    padding: 11.25rem 0px;
  }
}

@media screen and (max-width: 1024px) {
  .concept {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media screen and (max-width: 641px) {
  .concept {
    padding: 0;
  }
}

.concept .concept-inner {
  max-width: 380px;
  background-color: #fff;
  padding: 5rem;
}

@media screen and (max-width: 1024px) {
  .concept .concept-inner {
    padding: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .concept .concept-inner {
    padding: 0;
  }
}

.concept .concept-inner h2 {
  font-size: 36px;
  color: #000;
  margin-bottom: 40px;
}

@media screen and (max-width: 641px) {
  .concept .concept-inner h2 img {
    width: 159px;
  }
}

@media screen and (max-width: 641px) {
  .concept .concept-inner {
    padding-top: 5rem;
  }
}

.concept .concept-inner .concept-subtitle {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #000;
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .concept .concept-inner .concept-subtitle {
    margin-left: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 641px) {
  .concept .concept-inner .concept-subtitle {
    margin-bottom: 0.5rem;
    margin: 0 auto;
    font-size: 20px;
    letter-spacing: 0.07em;
  }
}

.concept .concept-inner .concept-copy {
  font-size: 20px;
  color: #000;
  letter-spacing: 0.8;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .concept .concept-inner .concept-copy {
    text-align: left;
  }
}

@media screen and (max-width: 641px) {
  .concept .concept-inner .concept-copy {
    padding: 2rem 2rem 5.5rem 2rem;
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

/*-----ここまでCONCEPT-----*/
/*-----ここからDAIRY-----*/
.daily {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  position: relative;
}

.daily .daily-inner {
  max-width: 1400px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner {
    width: 100%;
  }
}

.daily .daily-inner .daily-logo {
  position: relative;
  background-color: #fff;
  padding: 5rem;
  padding-top: 10rem;
  margin-bottom: -16rem;
  margin-left: 3rem;
  max-width: 250px;
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .daily-logo {
    margin-bottom: 0;
    margin-left: 0;
    padding: 3rem;
    padding-top: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .daily-logo {
    width: 163px;
    padding: 5rem 0 0 2rem;
  }
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .daily-logo img {
    height: 65px;
  }
}

.daily .daily-inner .lunch {
  padding-top: 10rem;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .daily .daily-inner .lunch {
    margin-left: 20rem;
  }
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .lunch {
    padding-top: 6rem;
    flex-direction: column-reverse;
  }
}

.daily .daily-inner .lunch .lunchimage {
  width: 700px;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .lunch .lunchimage {
    width: 100%;
  }
}

.daily .daily-inner .lunch .lunchimage img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .lunch .lunchimage img {
    height: 20rem;
  }
}

.daily .daily-inner .lunch .lunch-copy {
  background-color: #fff;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .daily .daily-inner .lunch .lunch-copy {
    max-width: 400px;
    min-width: 400px;
    margin-left: -10rem;
    margin-top: 15rem;
    padding: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .lunch .lunch-copy {
    margin: 0 auto;
    padding: 0 1rem;
    margin-bottom: 3rem;
    background-color: transparent;
  }
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .lunch .lunch-copy {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

.daily .daily-inner .lunch .lunch-copy h2 {
  margin-bottom: 30px;
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .lunch .lunch-copy h2 img {
    height: 16px;
    width: auto;
  }
}

.daily .daily-inner .lunch .lunch-copy p {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .lunch .lunch-copy p {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.daily .daily-inner .dinner {
  padding-top: 10rem;
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .dinner {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .daily .daily-inner .dinner {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .dinner {
    padding-top: 5rem;
  }
}

.daily .daily-inner .dinner .dinnerimage {
  width: 700px;
  margin-right: 10rem;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .dinner .dinnerimage {
    width: 100%;
  }
}

.daily .daily-inner .dinner .dinnerimage img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .dinner .dinnerimage img {
    height: 20rem;
  }
}

@media screen and (min-width: 1025px) {
  .daily .daily-inner .dinner .dinner-copy {
    position: relative;
    max-width: 420px;
    min-width: 420px;
    margin-right: 10rem;
    margin-right: -10rem;
    margin-top: 16rem;
    background-color: #fff;
    padding: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .daily .daily-inner .dinner .dinner-copy {
    margin: 0 auto;
    padding: 0 1rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .dinner .dinner-copy {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

.daily .daily-inner .dinner .dinner-copy h2 {
  margin-bottom: 30px;
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .dinner .dinner-copy h2 img {
    height: 16px;
    width: auto;
  }
}

.daily .daily-inner .dinner .dinner-copy p {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 641px) {
  .daily .daily-inner .dinner .dinner-copy p {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

/*-----ここまでDAILY-----*/
/*-----ここからDRINK MENU-----*/
.drink-menu {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  padding-top: 10rem;
}

@media screen and (max-width: 1024px) {
  .drink-menu {
    padding-top: 8rem;
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu {
    padding-top: 2rem;
  }
}

.drink-menu .drink-inner {
  max-width: 1400px;
  margin: 0 auto;
}

.drink-menu .drink-inner .drink-more {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  padding: 0 10rem;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .drink-menu .drink-inner .drink-more > div {
    width: calc(50% - (8rem / 2));
  }
  .drink-menu .drink-inner .drink-more > div:nth-of-type(odd) {
    margin-right: 8rem;
  }
  .drink-menu .drink-inner .drink-more > div:nth-of-type(n+3) {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more {
    flex-direction: column;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more > div {
    width: 100%;
  }
  .drink-menu .drink-inner .drink-more > div:nth-of-type(odd) {
    margin-right: 0;
  }
  .drink-menu .drink-inner .drink-more > div:not(:first-of-type) {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more {
    padding: 0;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more {
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }
}

.drink-menu .drink-inner .drink-more .latte {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .latte {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .latte h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .latte h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .latte .latteimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .latte p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .latte p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.drink-menu .drink-inner .drink-more .latte .latte-custom {
  margin-top: 3rem;
  width: 70%;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte .latte-custom {
    width: 100%;
  }
}

.drink-menu .drink-inner .drink-more .latte .latte-custom ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drink-menu .drink-inner .drink-more .latte .latte-custom ul li {
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte .latte-custom ul li {
    margin: 0 3rem 0 3rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .latte .latte-custom ul li {
    margin: 0;
  }
}

.drink-menu .drink-inner .drink-more .coffee {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .coffee {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .coffee {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .coffee h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .coffee h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .coffee h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .coffee h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .coffee .cofimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .coffee p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .coffee p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .coffee p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.drink-menu .drink-inner .drink-more .tea {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tea {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tea {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .tea h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tea h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tea h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tea h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .tea .teaimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .tea p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tea p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tea p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.drink-menu .drink-inner .drink-more .hotdog {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .hotdog {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .hotdog {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .hotdog h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .hotdog h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .hotdog h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .hotdog h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .hotdog .hotdogimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .hotdog p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .hotdog p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .hotdog p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.drink-menu .drink-inner .drink-more .tartine {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tartine {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tartine {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .tartine h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tartine h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tartine h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tartine h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .tartine .tarimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .tartine p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .tartine p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .tartine p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.drink-menu .drink-inner .drink-more .salad {
  max-width: 500px;
  position: relative;
  margin-top: 100px;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .salad {
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .salad {
    margin: 0 auto;
    margin-top: 6rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .salad {
    text-align: center;
  }
}

.drink-menu .drink-inner .drink-more .salad h2 {
  top: 0px;
  left: -50px;
  background-color: #fff;
  padding: 10px 10px 10px 0;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .salad h2 {
    top: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .salad h2 {
    left: 0;
    padding-left: 5px;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .salad h2 img {
    height: 16px;
  }
}

.drink-menu .drink-inner .drink-more .salad .salimg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.drink-menu .drink-inner .drink-more .salad p {
  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .drink-menu .drink-inner .drink-more .salad p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 641px) {
  .drink-menu .drink-inner .drink-more .salad p {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

/*-----ここまでDRINK MENU-----*/
/*-----ここからBAKED GOODS-----*/
.bakedgoods {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .bakedgoods {
    height: 700px;
  }
}

.bakedgoods .bakedgoods-inner {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner {
    flex-direction: column-reverse;
  }
}

.bakedgoods .bakedgoods-inner .bakeslider {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner .bakeslider {
    width: 100%;
  }
}

.bakedgoods .bakedgoods-inner .bakeslider .bakes-slidermain li {
  list-style: none;
  overflow: hidden;
}

.bakedgoods .bakedgoods-inner .bakeslider .bakes-slidermain li img {
  height: 700px;
  vertical-align: bottom;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner .bakeslider .bakes-slidermain li img {
    height: 60rem;
    width: 100%;
  }
}

@media screen and (max-width: 641px) {
  .bakedgoods .bakedgoods-inner .bakeslider .bakes-slidermain li img {
    height: 35rem;
  }
}

.bakedgoods .bakedgoods-inner .bakeslider .bakes-slidernav {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 3rem;
  width: 100%;
  padding: 0 1.75rem;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner .bakeslider .bakes-slidernav {
    bottom: 2rem;
  }
}

.bakedgoods .bakedgoods-inner .bakeslider .bakes-slidernav li {
  width: 25%;
  margin: 0 .75rem;
}

.bakedgoods .bakedgoods-inner .bakeslider .bakes-slidernav img {
  width: 100%;
}

.bakedgoods .bakedgoods-inner .bakedgoods-copy {
  width: 50%;
  max-width: 300px;
  min-width: 300px;
  margin-right: 19rem;
  margin-top: 20rem;
}

@media screen and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner .bakedgoods-copy {
    padding: 0 1rem;
    width: 100%;
    min-width: auto;
    text-align: center;
    margin: 0 auto;
    margin-top: 8rem;
    margin-bottom: 4rem;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .bakedgoods .bakedgoods-inner .bakedgoods-copy {
    width: 100%;
    max-width: 55rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 10rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 641px) {
  .bakedgoods .bakedgoods-inner .bakedgoods-copy {
    margin-bottom: 7rem;
    max-width: 330px;
  }
}

.bakedgoods .bakedgoods-inner .bakedgoods-copy h2 {
  margin-bottom: 30px;
}

@media screen and (max-width: 641px) {
  .bakedgoods .bakedgoods-inner .bakedgoods-copy h2 img {
    width: 248px;
  }
}

.bakedgoods .bakedgoods-inner .bakedgoods-copy p {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 641px) {
  .bakedgoods .bakedgoods-inner .bakedgoods-copy p {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

/*-----ここまでBAKED GOODS-----*/
/*-----ここからJUICE-----*/
.juice {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;
}

.juice .juice-inner {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media screen and (max-width: 641px) {
  .juice .juice-inner {
    max-width: 320px;
    padding-bottom: 5rem;
  }
}

.juice .juice-inner .ps {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-bottom: 2rem;
  margin-right: 5rem;
  color: #999;
  letter-spacing: 0.07em;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .ps {
    margin-top: 5rem;
    margin-left: 1rem;
    margin-right: 0;
  }
}

.juice .juice-inner h2 {
  z-index: 1;
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner h2 {
    position: absolute;
    top: 10rem;
    right: 0;
    width: 57.5%;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner h2 {
    position: relative;
    right: 0;
    margin: 0 auto 30px;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner h2 img {
    width: 220px;
  }
}

.juice .juice-inner .juice-wrap {
  position: relative;
}

.juice .juice-inner .juice-box {
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box {
    margin-bottom: 3rem;
    flex-direction: column;
  }
}

.juice .juice-inner .juice-box .juice-box-inner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner {
    flex-direction: column;
    width: 100%;
  }
}

.juice .juice-inner .juice-box .juice-box-inner > img {
  object-fit: cover;
  flex: 0 0 auto;
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner .juice-box .juice-box-inner > img {
    width: 42.5%;
    height: 700px;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner > img {
    height: 60rem;
    width: 100%;
  }
}

@media screen and (max-width: 641px) {
  .juice .juice-inner .juice-box .juice-box-inner > img {
    height: 35rem;
  }
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-slider-wrap {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-slider-wrap {
    width: 100%;
  }
}

.juice .juice-inner .juice-box .juice-box-inner .juice-copy {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy {
    min-height: 700px;
    padding-top: 20rem;
    padding-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy {
    padding: 0 1rem;
    flex-direction: column;
  }
}

.juice .juice-inner .juice-box .juice-box-inner .juice-copy img {
  max-width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy img {
    max-width: 70%;
    display: block;
    margin: 0 auto;
  }
}

.juice .juice-inner .juice-box .juice-box-inner .juice-copy .txt {
  margin-top: 5rem;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  margin-right: 3rem;
  width: 50%;
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy .txt {
    padding-left: 3rem;
    padding-bottom: 7rem;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy .txt {
    margin-top: 3rem;
    order: -1;
    width: 100%;
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 641px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy .txt {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 0.07em;
  }
}

.juice .juice-inner .juice-box .juice-box-inner .juice-copy h3 {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1rem;
}

@media screen and (max-width: 641px) {
  .juice .juice-inner .juice-box .juice-box-inner .juice-copy h3 {
    line-height: 1.8;
    font-size: 12px;
  }
}

.juice .juice-inner .juice-box .juice-box-inner .juice-copy h3 span {
  font-size: 25px;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.juice .juice-inner .juice-box .juice-copy01 .txt {
  background: url(../image/number01.svg) bottom 8rem right no-repeat;
  background-size: 18.4rem auto;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-copy01 .txt {
    background-size: 15rem auto;
    background-position: center right;
  }
}

.juice .juice-inner .juice-box .juice-copy02 .txt {
  background: url(../image/number02.svg) bottom 8rem right no-repeat;
  background-size: 20.6rem auto;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-copy02 .txt {
    background-size: 16rem auto;
    background-position: center right;
  }
}

.juice .juice-inner .juice-box .juice-copy03 .txt {
  background: url(../image/number03.svg) bottom 8rem right no-repeat;
  background-size: 18rem auto;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .juice-box .juice-copy03 .txt {
    background-size: 15rem auto;
    background-position: center right;
  }
}

.juice .juice-inner .slider-btn {
  display: flex;
}

@media screen and (min-width: 1025px) {
  .juice .juice-inner .slider-btn {
    position: absolute;
    bottom: 12rem;
    right: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .slider-btn {
    justify-content: flex-end;
    width: calc(100% - 2rem);
  }
}

.juice .juice-inner .slider-btn .rightbtn, .juice .juice-inner .slider-btn .leftbtn {
  width: 30px;
  height: 30px;
}

.juice .juice-inner .slider-btn .rightbtn {
  background: url(../image/btn-r.svg) center center no-repeat;
  background-size: contain;
}

.juice .juice-inner .slider-btn .leftbtn {
  background: url(../image/btn-l.svg) center center no-repeat;
  background-size: contain;
  margin-right: 2.8rem;
}

@media screen and (max-width: 1024px) {
  .juice .juice-inner .slider-btn .leftbtn {
    margin-right: 1.5rem;
  }
}

/*-----ここまでJUICE-----*/
/*-----ここからMENU-LIST-----*/
.menu-list {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;
}

.menu-list a {
  display: block;
  max-width: 100%;
  background-color: #c3593f;
  text-align: center;
  margin: 0 auto;
  height: 100px;
  color: #000;
  text-decoration: none;
  transition: 0.3s;
}

.menu-list a:hover {
  background: #c3984f;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .menu-list a {
    width: 100%;
  }
}

.menu-list a .menulistcopy {
  padding-top: 3rem;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: bold;
}

.menu-list a .menulistcopy i {
  display: inline-block;
  font-size: 15px;
  margin: 0 10px 3px 10px;
}

/*-----ここからSPEND-----*/
.spend {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
}

.spend .spend-slider {
  max-width: 100%;
  overflow: hidden;
}

.spend .spend-inner {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.spend .spend-inner h2 {
  position: absolute;
  z-index: 2;
  padding: 5rem;
  background-color: #fff;
}

@media screen and (min-width: 1025px) {
  .spend .spend-inner h2 {
    top: 8rem;
    left: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner h2 {
    position: relative;
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner h2 img {
    height: 8rem;
    width: auto;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner h2 img {
    height: 10rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner h2 img {
    height: 65px;
  }
}

.spend .spend-inner .spend-block1 {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  margin-bottom: 20rem;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 {
    flex-direction: column;
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block1 {
    margin-bottom: 3rem;
  }
}

.spend .spend-inner .spend-block1 .spend-slider .spendimage {
  width: 1000px;
}

@media screen and (min-width: 1025px) {
  .spend .spend-inner .spend-block1 .spend-slider .spendimage {
    margin-top: 8rem;
    margin-right: 10rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spendimage {
    width: 80%;
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spendimage {
    width: 100%;
    margin-top: 0;
  }
}

.spend .spend-inner .spend-block1 .spend-slider .spendimage img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spendimage img {
    height: 20rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spendimage img {
    height: 30rem;
  }
}

.spend .spend-inner .spend-block1 .spend-slider .spend-copy1 {
  width: 50rem;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  padding: 4rem 5rem;
  margin-left: 45rem;
  margin-top: -17rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spend-copy1 {
    padding: 2rem;
    width: 25rem;
    margin-top: -3rem;
    margin-left: auto;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .spend-slider .spend-copy1 {
    padding: 2rem;
    width: 45rem;
    margin-top: -3rem;
    z-index: 2;
    margin-left: auto;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block1 .spend-slider .spend-copy1 {
    font-size: 15px;
    line-height: 1.8;
    padding: 3.5rem;
    letter-spacing: 0.07em;
  }
}

.spend .spend-inner .spend-block1 .spend-slider .spend-copy1 img {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block1 .spend-slider .spend-copy1 img {
    height: 16px;
    width: auto;
  }
}

.spend .spend-inner .spend-block1 .slider-btn {
  display: flex;
}

@media screen and (min-width: 1025px) {
  .spend .spend-inner .spend-block1 .slider-btn {
    position: absolute;
    top: 68rem;
    right: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .slider-btn {
    justify-content: flex-end;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .slider-btn {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .slider-btn {
    z-index: 3;
  }
}

.spend .spend-inner .spend-block1 .slider-btn .rightbtn, .spend .spend-inner .spend-block1 .slider-btn .leftbtn {
  width: 30px;
  height: 30px;
}

.spend .spend-inner .spend-block1 .slider-btn .rightbtn {
  background: url(../image/btn-r.svg) center center no-repeat;
  background-size: contain;
}

.spend .spend-inner .spend-block1 .slider-btn .leftbtn {
  background: url(../image/btn-l.svg) center center no-repeat;
  background-size: contain;
  margin-right: 2.8rem;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .slider-btn .leftbtn {
    margin-right: 1.5rem;
  }
}

.spend .spend-inner .spend-block1 .bg-box-y {
  position: absolute;
  left: 20rem;
  bottom: -2rem;
  width: 30rem;
  height: 30rem;
  background-color: #c3984f;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .bg-box-y {
    left: 0;
    bottom: 0;
    width: 15rem;
    height: 15rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block1 .bg-box-y {
    left: 0;
    width: 20rem;
    height: 20rem;
    z-index: -1;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block1 .bg-box-y {
    display: none;
  }
}

.spend .spend-inner .spend-block2 {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 {
    flex-direction: column;
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 {
    margin-bottom: 10rem;
  }
}

.spend .spend-inner .spend-block2 .spendimage2 {
  width: 700px;
  margin-right: 10rem;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spendimage2 {
    width: 90%;
    margin-left: 0;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spendimage2 {
    width: 60%;
    margin-left: auto;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block2 .spendimage2 {
    width: 100%;
  }
}

.spend .spend-inner .spend-block2 .spendimage2 img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spendimage2 img {
    height: 20rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spendimage2 img {
    height: 30rem;
  }
}

.spend .spend-inner .spend-block2 .spend-copy2 {
  width: 50rem;
  height: 12rem;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  padding: 5rem;
  margin-right: -10rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spend-copy2 {
    padding: 2rem;
    width: 35rem;
    height: auto;
    margin-right: 0;
    margin-top: -3rem;
    width: 100%;
    box-sizing: border-box;
    max-width: 35rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .spend-copy2 {
    padding: 2rem;
    width: 50rem;
    margin-top: -3rem;
    z-index: 2;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block2 .spend-copy2 {
    font-size: 15px;
    line-height: 1.8;
    width: 30rem;
    padding: 3.5rem;
    letter-spacing: 0.07em;
  }
}

.spend .spend-inner .spend-block2 .spend-copy2 img {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block2 .spend-copy2 img {
    height: 16px;
    width: auto;
  }
}

.spend .spend-inner .spend-block2 .bg-box-o {
  position: absolute;
  right: 0rem;
  top: 20rem;
  width: 40rem;
  height: 30rem;
  background-color: #C3593F;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block2 .bg-box-o {
    width: 30rem;
    height: 20rem;
    top: 20rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block2 .bg-box-o {
    display: none;
  }
}

.spend .spend-inner .spend-block3 {
  padding-top: 7rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 20rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 {
    flex-direction: column;
    margin-bottom: 6rem;
    padding-top: 0;
  }
}

.spend .spend-inner .spend-block3 .spendimage3 {
  width: 700px;
  position: relative;
  z-index: 1;
  margin-left: 29rem;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage3 {
    width: 90%;
    margin-left: 0;
    margin: 0 auto;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage3 {
    width: 60%;
    margin-left: auto;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block3 .spendimage3 {
    width: 100%;
  }
}

.spend .spend-inner .spend-block3 .spendimage3 img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage3 img {
    height: 20rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage3 img {
    height: 30rem;
  }
}

.spend .spend-inner .spend-block3 .spend-copy3 {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  padding: 5rem;
  position: relative;
  margin-top: 26rem;
  z-index: 1;
}

@media screen and (min-width: 1025px) {
  .spend .spend-inner .spend-block3 .spend-copy3 {
    width: 50rem;
    height: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spend-copy3 {
    padding: 2rem;
    width: 25rem;
    margin-top: -3rem;
    margin-left: auto;
  }
}

@media screen and (min-width: 1025px) {
  .spend .spend-inner .spend-block3 .spend-copy3 {
    margin-left: -30rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spend-copy3 {
    padding: 2rem;
    width: 50rem;
    margin-top: -3rem;
    z-index: 2;
    margin-left: auto;
    text-align: center;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block3 .spend-copy3 {
    font-size: 15px;
    line-height: 1.8;
    padding: 3.5rem;
    letter-spacing: 0.07em;
  }
}

.spend .spend-inner .spend-block3 .spend-copy3 img {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block3 .spend-copy3 img {
    height: 16px;
    width: auto;
  }
}

.spend .spend-inner .spend-block3 .spendimage4 {
  width: 400px;
  left: 10rem;
  top: -25rem;
  position: absolute;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage4 {
    display: none;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage4 {
    display: flex;
    width: 25rem;
    left: 5rem;
    top: -3rem;
  }
}

.spend .spend-inner .spend-block3 .spendimage4 img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .spendimage4 img {
    height: 25rem;
  }
}

.spend .spend-inner .spend-block3 .bg-box-g {
  position: absolute;
  left: 0rem;
  top: -3rem;
  width: 40rem;
  height: 30rem;
  background-color: #3c5f4a;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .bg-box-g {
    top: 11rem;
    width: 15rem;
    height: 15rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block3 .bg-box-g {
    top: 11rem;
    width: 20rem;
    height: 20rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block3 .bg-box-g {
    display: none;
  }
}

.spend .spend-inner .spend-block4 {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 {
    flex-direction: column;
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 {
    margin-bottom: 10rem;
  }
}

.spend .spend-inner .spend-block4 .spendimage4 {
  width: 800px;
  margin-right: 10rem;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spendimage4 {
    width: 90%;
    margin-left: 0;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spendimage4 {
    width: 60%;
    margin-left: auto;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block4 .spendimage4 {
    width: 100%;
  }
}

.spend .spend-inner .spend-block4 .spendimage4 img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spendimage4 img {
    height: 20rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spendimage4 img {
    height: 30rem;
  }
}

.spend .spend-inner .spend-block4 .spend-copy4 {
  width: 50rem;
  height: 12rem;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  padding: 5rem;
  margin-right: -15rem;
  margin-top: 30rem;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spend-copy4 {
    padding: 2rem;
    width: 35rem;
    height: auto;
    margin-right: 0;
    margin-top: -3rem;
    width: 100%;
    box-sizing: border-box;
    max-width: 35rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .spend-copy4 {
    padding: 2rem;
    width: 50rem;
    margin-top: -3rem;
    z-index: 2;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block4 .spend-copy4 {
    font-size: 15px;
    line-height: 1.8;
    width: 30rem;
    padding: 3.5rem;
    letter-spacing: 0.07em;
  }
}

.spend .spend-inner .spend-block4 .spend-copy4 img {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block4 .spend-copy4 img {
    height: 16px;
    width: auto;
  }
}

.spend .spend-inner .spend-block4 .bg-box-b {
  position: absolute;
  right: 0rem;
  top: -10rem;
  width: 40rem;
  height: 30rem;
  background-color: #00507E;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .spend .spend-inner .spend-block4 .bg-box-b {
    width: 30rem;
    height: 20rem;
    top: 5rem;
  }
}

@media screen and (max-width: 641px) {
  .spend .spend-inner .spend-block4 .bg-box-b {
    display: none;
  }
}

/*-----ここまでSPEND-----*/
/*-----ここからINSTA-----*/
.insta {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .insta {
    padding-bottom: 8rem;
  }
}

.insta .insta-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 90px;
  position: relative;
}

@media screen and (max-width: 641px) {
  .insta .insta-inner {
    padding-top: 5rem;
  }
}

.insta .insta-inner h2 {
  margin-bottom: 2.5rem;
  width: 240px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1024px) {
  .insta .insta-inner h2 {
    text-align: center;
    margin: 0 auto;
  }
}

@media screen and (max-width: 641px) {
  .insta .insta-inner h2 {
    width: 204px;
    margin-bottom: 4.5rem;
  }
}

.insta .insta-inner .insta-more {
  font-size: 13px;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 10rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1025px) {
  .insta .insta-inner .insta-more {
    position: absolute;
    right: 0;
    top: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .insta .insta-inner .insta-more {
    margin: 0 2rem 0 auto;
  }
}

.insta .insta-inner .insta-more a {
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
}

.insta .insta-inner .insta-more a:hover {
  opacity: 1;
  color: #3c5f4a;
}

.insta .insta-inner .insta-more::after {
  content: "";
  background: url(../image/more.svg) no-repeat;
  display: block;
  width: 9px;
  height: 9px;
}

@media screen and (max-width: 1024px) {
  .insta .insta-inner #sb_instagram {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.insta .insta-inner #sb_instagram #sbi_images {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {
  .insta .insta-inner #sb_instagram #sbi_images .sbi_item {
    margin-right: 5rem !important;
    margin-bottom: 5rem !important;
    max-width: calc(20% - (20rem / 5));
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .insta .insta-inner #sb_instagram #sbi_images .sbi_item {
    max-width: 20%;
  }
}

@media screen and (max-width: 641px) {
  .insta .insta-inner #sb_instagram #sbi_images .sbi_item {
    width: 50%;
  }
}

@media screen and (min-width: 642px) {
  .insta .insta-inner #sb_instagram #sbi_images .sbi_item:nth-of-type(5n) {
    margin-right: 0 !important;
  }
}

.insta .insta-inner #sb_instagram #sbi_images img {
  max-width: 100%;
  vertical-align: bottom;
}

/*-----ここまでINSTA-----*/
/*-----ここからCOMING-----*/
.coming {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  position: relative;
}

.coming .com-inner {
  width: 100%;
  text-align: center;
}

.coming .com-inner img {
  margin: 0 auto;
  margin-bottom: 3.5rem;
}

/*-----ここからEVENT-----*/
.event {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  padding: 12rem 1rem 10rem;
}

@media screen and (max-width: 1024px) {
  .event {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.event .event-inner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.event .event-inner h2 {
  margin: 0 auto 10rem;
  width: 125px;
}

@media screen and (max-width: 1024px) {
  .event .event-inner h2 {
    margin-bottom: 6rem;
  }
}

.event .event-more {
  font-size: 13px;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 10rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 1025px) {
  .event .event-more {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .event .event-more {
    margin: 3rem 0 0 auto;
  }
}

.event .event-more a {
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
}

.event .event-more a:hover {
  color: #3c5f4a;
  opacity: 1;
}

.event .event-more::after {
  content: "";
  background: url(../image/more.svg) no-repeat;
  display: block;
  width: 9px;
  height: 9px;
}

.event .event-main li {
  margin-bottom: 10rem;
}

@media screen and (max-width: 1024px) {
  .event .event-main li {
    margin-bottom: 4.5rem;
  }
}

.event .event-main a {
  display: flex;
  align-items: center;
  width: 100%;
  transition: opacity ease 0.3s;
}

.event .event-main a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .event .event-main a {
    flex-direction: column;
  }
}

@media screen and (min-width: 1025px) {
  .event .event-main .img {
    width: 44.5%;
  }
}

@media screen and (max-width: 1024px) {
  .event .event-main .img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.event .event-main .img img {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .event .event-main .cont {
    width: 55.5%;
    padding-left: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .event .event-main .cont {
    width: 100%;
  }
}

.event .event-main .cont .day {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  border-left: 3px solid #19507C;
  font-size: 2.5rem;
  text-align: center;
  display: inline-block;
  padding-left: 2rem;
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .event .event-main .cont .day {
    margin-bottom: 1rem;
  }
}

.event .event-main .cont .day strong {
  padding-top: .5rem;
  display: block;
  font-size: 3.5rem;
}

.event .event-main .cont .txt {
  width: 100%;
  font-size: 2.5rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.event .event-main .cont .cat {
  margin-top: 3rem;
}

.event .event-main .cont .cat span {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #00507C;
  font-size: 1.3rem;
  font-weight: 400;
  padding-right: 1.5rem;
}

.event .event-main .cont .subttl {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .event .event-main .cont .subttl {
    line-height: 1.75;
  }
}

/*-----ここまでEVENT-----*/
/*-----ここからfooter-----*/
.foot {
  border: solid 10px #000;
}

@media screen and (min-width: 1025px) {
  .foot {
    height: 700px;
  }
}

.foot .footer-inner {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner {
    width: 100%;
    flex-direction: column-reverse;
  }
}

.foot .footer-inner .footer-menu {
  background-color: #c3984f;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1025px) {
  .foot .footer-inner .footer-menu {
    margin-right: -10px;
    height: 700px;
  }
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .footer-menu {
    width: 100%;
    padding: 3rem 2rem;
    box-sizing: border-box;
  }
}

.foot .footer-inner .footer-menu .footlogo {
  width: 100px;
  margin-right: 4rem;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .footer-menu .footlogo {
    margin-right: 3rem;
  }
}

.foot .footer-inner .footer-menu .sitemap {
  margin-left: 4rem;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .footer-menu .sitemap {
    margin-left: 0;
    flex: 0 0 auto;
  }
}

.foot .footer-inner .footer-menu .sitemap .menucontents {
  list-style: none;
}

.foot .footer-inner .footer-menu .sitemap .menucontents li {
  margin-bottom: 25px;
}

.foot .footer-inner .footer-menu .sitemap .menucontents li:last-child {
  margin-top: 50px;
}

.foot .footer-inner .footer-menu .sitemap .snsicon {
  list-style: none;
}

.foot .footer-inner .footer-menu .sitemap .snsicon li {
  display: inline-block;
  margin-right: 10px;
}

.foot .footer-inner .footer-menu .sitemap .snsicon i {
  font-size: 18px;
}

.foot .footer-inner .info-inner {
  width: 50%;
  background-color: #000;
  border-left: solid 10px #000;
  border-bottom: solid 10px #000;
}

@media screen and (min-width: 1025px) {
  .foot .footer-inner .info-inner {
    height: 700px;
  }
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner {
    border-left: none;
    width: 100%;
    box-sizing: border-box;
  }
}

.foot .footer-inner .info-inner .footer-info {
  background-color: #3c5f4a;
  width: 100%;
  height: 345px;
  border-bottom: solid 5px #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-info {
    flex-direction: column;
  }
}

.foot .footer-inner .info-inner .footer-info .infologo {
  margin-right: 2.5rem;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-info .infologo {
    margin: 0 auto;
    margin-bottom: 3rem;
  }
}

.foot .footer-inner .info-inner .footer-info .access {
  margin-left: 3rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-info .access {
    margin: 0 auto;
  }
}

.foot .footer-inner .info-inner .footer-info .access .accesslist li {
  list-style: none;
  font-size: 13px;
  color: #000;
  letter-spacing: 1px;
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 15px;
  display: flex;
}

.foot .footer-inner .info-inner .footer-info .access .accesslist .access-clock::before {
  content: "";
  background: url(../image/clockicon.svg) no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 10px 3px 0;
}

.foot .footer-inner .info-inner .footer-info .access .accesslist .access-tel::before {
  content: "";
  background: url(../image/telicon.svg) no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 10px 3px 0;
}

.foot .footer-inner .info-inner .footer-info .access .accesslist .access-pin::before {
  content: "";
  background: url(../image/pinicon.svg) no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 10px 3px 0;
}

.foot .footer-inner .info-inner .footer-map {
  background-color: #00507e;
  width: 100%;
  border-top: solid 5px #000;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1025px) {
  .foot .footer-inner .info-inner .footer-map {
    height: 345px;
  }
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-map {
    height: 345px;
  }
}

.foot .footer-inner .info-inner .footer-map .mapbg {
  position: absolute;
  width: 100%;
  height: 355px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-map .mapbg {
    height: 100%;
  }
}

.foot .footer-inner .info-inner .footer-map a {
  position: absolute;
}

@media screen and (min-width: 1025px) {
  .foot .footer-inner .info-inner .footer-map a {
    top: 220px;
    right: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-map a {
    left: 1rem;
    top: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .foot .footer-inner .info-inner .footer-map a img {
    width: 80px;
  }
}

/*-----ここまでfooter-----*/
.slider-btn p {
  transition: opacity ease 0.3s;
}

.slider-btn p:hover {
  opacity: 0.6;
}

.slider-btn p:hover {
  cursor: pointer;
}

/*******************************

    LOWER

*******************************/
.lower {
  position: relative;
  overflow: hidden;
  padding: 0 calc(10px + 2rem) 10rem;
}

.lower::before, .lower::after {
  content: "";
  display: block;
  width: 10px;
  background: #000;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.lower::before {
  left: 0;
  top: 0;
}

.lower::after {
  right: 0;
  top: 0;
}

.lower-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.lowerHeader {
  position: relative;
  margin: 7.5rem auto 11rem;
  max-width: 1200px;
}

@media screen and (max-width: 1024px) {
  .lowerHeader {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
}

.lowerHeader_ttl {
  margin: 0 auto;
  text-align: center;
}

.lowerHeader-sns {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1025px) {
  .lowerHeader-sns {
    top: 2.5rem;
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .lowerHeader-sns {
    margin-bottom: 3rem;
  }
}

.lowerHeader-sns li {
  margin-right: 2rem;
}

@media screen and (max-width: 1024px) {
  .lowerHeader-sns li {
    margin-right: 1rem;
  }
}

.lowerHeader-sns a {
  transition: opacity ease 0.3s;
  font-size: 18px;
}

.lowerHeader-sns a:hover {
  opacity: 0.6;
}

.pager .nav-links {
  display: flex;
  align-items: center;
}

.pager .nav-links a,
.pager .nav-links span {
  margin-right: 1rem;
}

.pager .nav-links a,
.pager .nav-links .current {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  border: none;
  width: 3rem;
  height: 3rem;
  text-decoration: none;
  font-size: 1.2rem;
  color: #000;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pager .nav-links .current {
  background: #000;
  color: #fff;
}

.pager .nav-links a {
  transition: opacity ease 0.3s;
}

.pager .nav-links a:hover {
  opacity: 0.6;
}

/*******************************

    ARCHIVE

*******************************/
.archive-ttl {
  margin-bottom: 10rem;
}

@media screen and (max-width: 1024px) {
  .archive-ttl {
    margin-bottom: 3rem;
  }
}

.archive-inner {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 1024px) {
  .archive-inner {
    flex-direction: column;
  }
}

.archiveMain {
  flex: 0 0 auto;
}

@media screen and (min-width: 1025px) {
  .archiveMain {
    width: calc(80% - 10rem);
    margin-right: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .archiveMain {
    margin-bottom: 6rem;
  }
}

.archiveMain-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10rem;
}

@media screen and (max-width: 641px) {
  .archiveMain-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .archiveMain-list li {
    width: calc(50% - (10rem / 2));
  }
  .archiveMain-list li:nth-of-type(odd) {
    margin-right: 10rem;
  }
  .archiveMain-list li:nth-of-type(n+3) {
    margin-top: 10rem;
  }
}

@media screen and (max-width: 641px) {
  .archiveMain-list {
    flex-direction: row;
  }
}

@media screen and (max-width: 641px) {
  .archiveMain-list li {
    width: calc(50% - (1rem / 2));
  }
  .archiveMain-list li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .archiveMain-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .archiveMain-list {
    margin-bottom: 6rem;
  }
}

.archiveMain-list a {
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity ease 0.3s;
}

.archiveMain-list a:hover {
  opacity: 0.6;
}

.archiveMain-list figure {
  margin-bottom: 2rem;
}

.archiveMain-list figure img {
  width: 100%;
}

.archiveMain-list .txt {
  font-size: 1.3rem;
  line-height: 2.2;
  margin-bottom: 2.5rem;
}

.archiveMain-list .summary {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) {
  .archiveMain-list .summary {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.archiveMain-list .category {
  padding: .25rem 1.25rem .5rem;
  color: #fff;
}

.archiveMain-list .category-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.archiveMain-list .category-wrap p:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.archiveMain-list .category.cat-cat01, .archiveMain-list .category.cat-cat03 {
  background: #C3593F;
}

.archiveMain-list .category.cat-cat02, .archiveMain-list .category.cat-cat04 {
  background: #C3984F;
}

.archiveMain-list .day {
  letter-spacing: 0.025em;
}

@media screen and (max-width: 1024px) {
  .archiveMain-list .day {
    order: -1;
    margin-bottom: 1rem;
  }
}

.archiveSide {
  width: 100%;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  padding: 4.5rem 0;
}

.archiveSide-ttl {
  text-align: center;
  margin-bottom: 5rem;
}

.archiveSide-list li:not(:last-of-type) {
  margin-bottom: 2rem;
}

.archiveSide-list a {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  transition: opacity ease 0.3s;
}

.archiveSide-list a:hover {
  opacity: 0.6;
}

.archiveSide-list li a::before {
  content: "";
  display: block;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
}

.archiveSide-list li.cat-cat01 a::before {
  background: #C3593F;
}

.archiveSide-list li.cat-cat02 a::before {
  background: #C3984F;
}

.archiveSide-list li.cat-all a::before {
  background: #3C5F4A;
}

.eventArcMain {
  width: 100%;
}

.eventArc-list li {
  margin-bottom: 10rem;
}

@media screen and (max-width: 1024px) {
  .eventArc-list li {
    margin-bottom: 4.5rem;
  }
}

.eventArc-list li a {
  display: flex;
  align-items: center;
  width: 100%;
  transition: opacity ease 0.3s;
}

.eventArc-list li a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .eventArc-list li a {
    flex-direction: column;
  }
}

@media screen and (min-width: 1025px) {
  .eventArc-list .img {
    width: 44.5%;
  }
}

@media screen and (max-width: 1024px) {
  .eventArc-list .img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.eventArc-list .img img {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .eventArc-list .cont {
    width: 55.5%;
    padding-left: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .eventArc-list .cont {
    width: 100%;
  }
}

.eventArc-list .cont .day {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  border-left: 3px solid #19507C;
  font-size: 2.5rem;
  text-align: center;
  display: inline-block;
  padding-left: 2rem;
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .eventArc-list .cont .day {
    margin-bottom: 1rem;
  }
}

.eventArc-list .cont .day strong {
  padding-top: .5rem;
  display: block;
  font-size: 3.5rem;
}

.eventArc-list .cont .txt {
  width: 100%;
  font-size: 2rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.eventArc-list .cont .cat {
  margin-top: 3rem;
}

.eventArc-list .cont .cat span {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #00507C;
  font-size: 1.3rem;
  font-weight: 400;
  padding-right: 1.5rem;
}

.eventArc-list .cont .subttl {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .eventArc-list .cont .subttl {
    line-height: 1.75;
  }
}

/*******************************

    SINGLE

*******************************/
.single-ttl {
  font-size: 2.5rem;
  line-height: 2;
  letter-spacing: .07em;
}

@media screen and (max-width: 1024px) {
  .single-ttl {
    line-height: 1.75;
    margin-bottom: 1rem;
    width: 100%;
  }
}

.single-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -1.5rem;
  border-bottom: 3px solid #000;
  padding-bottom: .5rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .single-head {
    flex-direction: column;
  }
}

.single-head .day {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
  margin-bottom: 1.25rem;
  flex: 0 0 auto;
  padding-left: 3rem;
}

.single-inner {
  max-width: 800px;
  margin: 0 auto 9rem;
}

@media screen and (max-width: 1024px) {
  .single-inner {
    margin-bottom: 6rem;
  }
}

.singleThumb {
  margin-bottom: 4rem;
  text-align: center;
}

.singleMain img {
  max-width: 100%;
}

.singleMain .single-cont .ttl {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2em;
  line-height: 2;
}

.singleMain .single-cont p {
  line-height: 3;
  font-size: 1.3rem;
  margin-bottom: 4rem;
}

.singleMain .single-cont .img-1col {
  width: 100%;
}

.singleMain .single-cont .img-2col {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.singleMain .single-cont .img-2col img {
  display: block;
  max-width: 100%;
  width: calc(50% - 2rem);
}

@media screen and (max-width: 1024px) {
  .singleMain .single-cont .img-2col img {
    width: calc(50% - .5rem);
  }
}

.single-sns {
  display: flex;
  align-items: center;
  margin-bottom: 12rem;
}

@media screen and (max-width: 1024px) {
  .single-sns {
    margin-bottom: 6rem;
  }
}

.single-sns li {
  margin-right: 2rem;
}

@media screen and (max-width: 1024px) {
  .single-sns li {
    margin-right: 1rem;
  }
}

.single-sns a {
  transition: opacity ease 0.3s;
}

.single-sns a:hover {
  opacity: 0.6;
}

.single-event {
  flex: 0 0 auto;
}

@media screen and (min-width: 1025px) {
  .single-event {
    width: calc(75% - 10rem);
    margin-right: 10rem;
  }
}

.single-event-wrap {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .single-event-wrap {
    flex-direction: column;
  }
}

.single-info {
  border-top: 3px solid #000;
  width: 100%;
  margin-top: 1rem;
  padding-top: 5rem;
}

.single-info-ttl {
  text-align: center;
  margin-bottom: 5rem;
}

.single-info dl {
  margin-bottom: 4.5rem;
}

.single-info dl div {
  display: flex;
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.single-info dl dt {
  width: 11.5rem;
  color: rgba(77, 77, 77, 0.4);
}

.single-info dl dd {
  width: calc(100% - 11.5rem);
  font-weight: 400;
}

.single-info-btn a {
  display: block;
  text-align: center;
  border: 3px solid #000;
  padding: 1.75rem .75rem;
  background: #C3984F;
  transition: 0.3s;
}

.single-info-btn a:hover {
  background: #3C5F4A;
}

.view-btn {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.view-btn p {
  width: 11rem;
}

.view-btn a {
  font-family: urw-din, "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  transition: opacity ease 0.3s;
}

.view-btn a:hover {
  opacity: 0.6;
}

.view-btn .next a {
  justify-content: flex-end;
}

.view-btn .prev img {
  padding-right: 4rem;
}

@media screen and (max-width: 1024px) {
  .view-btn .prev img {
    padding-right: 1rem;
  }
}

.view-btn .next img {
  padding-left: 4rem;
}

@media screen and (max-width: 1024px) {
  .view-btn .next img {
    padding-left: 1rem;
  }
}

.singleMain strong {
  font-weight: bold;
}

.singleMain a {
  text-decoration: underline;
}

.singleMain blockquote {
  padding: 1rem 2rem;
  background: rgba(77, 77, 77, 0.1);
  margin-bottom: 4rem;
}

.singleMain blockquote p:last-of-type {
  margin-bottom: 0;
}

.head .headnav {
  transition: cubic-bezier(0.27, 0.51, 0.71, 1.03) 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: calc(50% + 15px);
  height: 100%;
  background: #c3984f;
  border: 10px solid #000;
  padding: 3rem;
  box-sizing: border-box;
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  pointer-events: auto;
}

@media screen and (max-width: 1024px) {
  .head .headnav {
    min-width: 250px;
  }
}

.head .headnav-inner li {
  margin-bottom: 1rem;
}

.head .headnav-inner a {
  display: block;
  padding: 1rem 0;
}

.head .headnav-inner .snsicon {
  margin-top: 3rem;
}

.head .headnav-inner .snsicon li {
  display: inline-block;
  margin-right: 10px;
}

.head .headnav-inner .snsicon i {
  font-size: 18px;
}

.head.is-open .headnav {
  transition: cubic-bezier(0.27, 0.51, 0.71, 1.03) 0.5s;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
