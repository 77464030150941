@charset "utf-8";
@import "reset";
@import "settings";
@import "icon";


html,
body {
  /* ルートのフォントサイズを10pxに設定しておく */
  font-size: 62.5%;
  // @include default_font;
  font-family: $sans;

}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  @include hover01;
}

img {
  max-width: 100%;
}

.of-cover{
  font-family: 'object-fit: cover;'
}

/* DISPLAY
------------------------------*/

@include lt-tbmin {
  .lt-tbminNone {
    display: none !important;
  }
}

@include st-tbmin {
  .st-tbminNone {
    display: none !important;
  }
}

@include lt-tblt {
  .lt-tbltNone {
    display: none !important;
  }
}

@include st-tblt {
  .st-tbltNone {
    display: none !important;
  }
}

@include lt-sp {
  .lt-spNone {
    display: none !important;
  }
}

@include st-sp {
  .st-spNone {
    display: none !important;
  }
}

//@include lt-pc {
//  .lt-pcNone {
//    display: none !important;
//  }
//}

@include tblt {
  .tbltNone {
    display: none !important;
  }
}


/*-----ここからheader-----*/
.head {
  .header-inner {
    .topborder {
      height: 10px;
      width: 100%;
      background-color: #000;
      position: fixed;
      z-index: 6;
    }

  }

  .nav-copy{
    font-size: 7px;
    letter-spacing: 0.25rem;
    font-family: sans-serif;
    font-weight: 600;
    font-style: normal;
    z-index: 5;
    transform: rotatez(90deg) translateX(100%);
    transform-origin: right top;
    position: absolute;
    right: 53px;
    top: 175px;
    @include st-tblt {
      display: none;
    }
  }
}

.scroll__container {
  position: absolute;
  z-index: 3;
  // right: 26px;
  right: 16px;
  // bottom: 110px;
  bottom: 100px;

  @include st-tblt {
    display: none;
  }

  .scroll {
    position: relative;
    color: #000;
    // height: 60px;
    height: 37px;
    padding: 2rem 0 0 1rem;
    writing-mode: vertical-rl;

    .scroll-copy {
      font-size: 9px;
      /*font-family:'Noto Sans JP', sans-serif; ;*/
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-style: normal;
      position: absolute;
      right: 26px;
      // bottom: 105px;
      bottom: 68px;
    }
  }

  .scroll::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 31px;
    background-color: #000;
    z-index: 10;
  }

  .scroll::after {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 28px;
    background-color: #000;
    top: 0;
    z-index: 9;
    border-radius: 100%;
  }
}

.fixed-menu {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;

  img {
    width: 150px;
    z-index: 2;

    @include st-tblt {
      width: 7rem;
      ;
    }
  }

  .ham {
    right: 90px;
    top: 30px;
    position: absolute;
    z-index: 3;

    @include st-tblt {
      right: 5rem;
      top: 0.5rem;
    }

    cursor: pointer;
  }

  .ham_line {
    position: absolute;
    left: 10px;
    width: 43px;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.4s;

    @include st-tblt {
      width: 3rem;
    }
  }

  .ham_line1 {
    top: 10px;
  }

  .ham_line2 {
    top: 18px;
  }

  .ham_line3 {
    top: 26px;
  }

  .clicked .ham_line1 {
    transform: rotate(20deg);
    top: 20px;
  }

  .clicked .ham_line2 {
    width: 0px;
  }

  .clicked .ham_line3 {
    transform: rotate(-20deg);
    top: 20px
  }

  ;
}

/*-----ここまでheader-----*/


/*-----ここからMAIN-----*/
.kv {
  border: solid 10px #000;
  position: relative;


  @include lt-tblt {
    height: calc(100vh - 20px);
    min-height: 750px;
    max-height: 50vw;
  }

  .kv-inner {
    height: calc(100% - 100px);
    // background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url(../image/dammy1.png);
    // background-size: cover;

    .kv-slider{
      position: relative;
      width: 100%;
      height: 100%;
      li{
        width: 100%;
        height: 100%;
        position: relative;
        img{
          max-width: inherit;
          display: block;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    @include lt-tblt {

      margin-right: 100px;
    }

    @include st-tblt {
      width: 100%;
    }

    .hand-copy {
      position: absolute;

      @include lt-tblt {
bottom: 150px;
        right: 150px;
      }

      @include st-tblt {
        bottom: calc(100px + 5vw);
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }

      @include tblt {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }

      img {
        // height: 105px;

        @include st-tblt {
          width: 90%;
        }
      }
    }

    .toplogo {
      position: absolute;
      top: 30%;
      left: 100px;
      text-align: center;

      @include st-tblt {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }

      @include tblt {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }

      p {
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.1rem;
        color: #fff;
        margin-bottom: 40px;

        @include st-tblt {
          margin-left: -0.8rem;
        }
      }

      img {
        width: 100px;
      }
    }

    .main-news {
      height: 100px;
      width: 400px;
      background-color: #f2f2f2;
      display: flex;
      justify-content: flex-start;
      position: absolute;
      bottom: 50px;

      a {
        display: flex;
        width: 100%;
      }

      @include st-tblt {
        width: 100%;
        bottom: 0;
      }

      img {
        width: 100px;
        height: 100px;
      }

      .main-news-copy {
        margin-left: 30px;

        @include st-tblt {
          margin-left: 1.5rem;
        }

        .main-news-title {
          font-size: 13px;

          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.1rem;
          margin-top: 30px;

          @include st-tblt {
            margin-top: 3rem;
          }
        }

        .main-news-day {
          font-size: 10px;

          font-style: normal;
          font-weight: 400;
          color: #999999;
          margin-top: 15px;
        }
      }
    }

    .main-viewmore {
      position: absolute;
      font-size: 13px;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-style: normal;

      @include lt-tblt {

        bottom: 65px;
        left: 426px;
      }

      @include st-tblt {
        bottom: 1rem;
        right: 1rem;
      }

      a {
        text-decoration: none;
        color: #000;
        display: block;
      }
    }

    .main-viewmore::before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 1px;
      background-color: #000;
      position: absolute;
      top: 6px;
      left: -42px;
    }
  }

  .slick-list.draggable,
  .slick-track{
    height: 100%;
  }

  .kv-dots{
    position: absolute;
    bottom: -20px;
    right: 0;

    display: flex;
    @include st-tblt{
      bottom: 1rem;
      right: 1rem;
    }
    li{
      margin-left: 10px;
    }
    
    .slick-active button{
      background: #000;
    }
    button{
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #000;
      box-sizing: border-box;
      font-size: 0;
      background: #fff;
    }
  }
}

/*-----ここまでMAIN-----*/


/*-----ここからPICK UP-----*/
.pickup {
  // height: 700px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  padding: 0 1rem;

  @include tblt {
    height: auto;
  }

  .pickup-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 9rem;
    padding-bottom: 10rem;

    @include st-tblt {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    h2 {
      margin-bottom: 45px;

      @include st-tblt {
        text-align: center;
        margin-bottom: 3rem;
      }
    }

    .pickup-list {
      @include block-4col($mr:5.3rem,$spcol:'2',$spmt:2rem);
      a{
        display: block;
      }
      p{
        font-size: 2rem;
        font-weight: bold;
        margin-top: 1rem;
        letter-spacing: 0.07em;
        line-height: 1.8;
        @include st-sp{
          font-size: 15px;
        }
      }
    }
  }
}

/*-----ここまでPICK UP-----*/


/*-----ここからCONCEPT-----*/
.concept {
  background-image: url(../image/concept-bg.jpg);
  background-color:rgba(255,255,255,0.5);
  background-blend-mode:lighten;
  background-size: cover;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  @include lt-tblt {
    padding: 11.25rem 0px;
    // min-height: 700px;
  }

  @include st-tblt {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @include st-sp {
    padding: 0;
  }

  .concept-inner {
    max-width: 380px;
    background-color: #fff;
      padding: 5rem;

    @include st-tblt{
      padding: 3rem;
    }

    @include st-sp {
      padding: 0;
    }

    h2 {
      font-size: 36px;
      color: #000;
      margin-bottom: 40px;
      img{
        @include st-sp {
          width: 159px;
        }
      }
    }

    @include st-sp {
      padding-top: 5rem;
    }

    .concept-subtitle {
      font-size: 25px;

      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.1rem;
      color: #000;
      margin-bottom: 30px;

      @include st-tblt {
        margin-left: 1rem;
        text-align: center;
      }
      @include st-sp {
        margin-bottom: 0.5rem;
        margin: 0 auto;
        font-size: 20px;
        letter-spacing: 0.07em;
      }
    }

    .concept-copy {
      font-size: 20px;
      color: #000;
      letter-spacing: 0.8;
      line-height: 40px;

      font-style: normal;
      font-weight: 400;

      @include st-tblt {
        text-align: left;
      }
      @include st-sp {
        padding: 2rem 2rem 5.5rem 2rem;
        font-size: 15px;
        line-height: 1.8;
        letter-spacing: 0.07em;
      }
    }
  }
}

/*-----ここまでCONCEPT-----*/




@mixin menu-txt {

  margin-top: 35px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;

  font-style: normal;
  font-weight: 400;

  @include st-tblt {
    padding: 0 1rem;
  }
}

/*-----ここからDAIRY-----*/
.daily {
  // min-height: 2100px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  position: relative;

  @include st-tblt {
    // height: 220rem;
  }

  @include tblt {
    // height: 200rem;
  }

  .daily-inner {
    max-width: 1400px;
    margin: 0 auto;
    

    @include st-tblt {
      width: 100%;
    }
    .daily-logo{
      position: relative;
      background-color: #fff;
      padding: 5rem;
      padding-top: 10rem;
      margin-bottom: -16rem;
      margin-left: 3rem;
      max-width: 250px;
      z-index: 3;

      @include st-tblt{
        margin-bottom:  0;
        margin-left: 0;
        padding: 3rem;
        padding-top: 5rem;
      }
      @include st-sp {
        width: 163px;
        padding: 5rem 0 0 2rem;
      }
      img{
        @include st-sp{
          height: 65px;
        }
      }
    }
    

    .lunch {
      padding-top: 10rem;
      display: flex;

      @include lt-tblt{
        margin-left: 20rem;
      }

      @include st-tblt {
        padding-top: 6rem;
        flex-direction: column-reverse;
      }

      .lunchimage {
        width: 700px;

        @include st-tblt {
          width: 100%;
        }

        img {
          width: 100%;
          height: 450px;
          object-fit: cover;

          @include st-tblt {
            height: 20rem;
          }
        }
      }

      .lunch-copy {
        background-color: #fff;
        position: relative;
        @include lt-tblt{
          
        max-width: 400px;
        min-width: 400px;
        margin-left: -10rem;
        margin-top: 15rem;
        padding: 5rem;
        }

        @include st-tblt {
          margin: 0 auto;
          padding: 0 1rem;
          margin-bottom: 3rem;
          background-color: transparent;
        }
        @include st-sp {
          padding-left: 3rem;
          padding-right: 2rem;
        }


        h2 {
          margin-bottom: 30px;
          img{
            @include st-sp {
              height: 16px;
              width: auto;
            }
          }
        }

        p {
          font-size: 20px;
          letter-spacing: 1px;
          line-height: 40px;

          font-style: normal;
          font-weight: 400;
          @include st-sp{
            font-size: 15px;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }
    }

    .dinner {
      padding-top: 10rem;
      display: flex;
      flex-direction: row-reverse;

      @include st-tblt {
        flex-direction: column-reverse;

      }

      @include tblt {
        flex-direction: column-reverse;
      }
      @include st-sp {
        padding-top: 5rem;
      }

      .dinnerimage {
        width: 700px;
        margin-right: 10rem;

        @include st-tblt {
          width: 100%;
        }

        img {
          width: 100%;
          height: 450px;
          object-fit: cover;

          @include st-tblt {
            height: 20rem;
          }
        }
      }

      .dinner-copy {
        @include lt-tblt{
        position: relative;
        max-width: 420px;
        min-width: 420px;
        margin-right: 10rem;
        margin-right: -10rem;
        margin-top: 16rem;
        background-color: #fff;
        padding: 5rem;
        }

        @include st-tblt {
          margin: 0 auto;
          padding: 0 1rem;
          margin-bottom: 3rem;
        }
        @include st-sp {
          padding-left: 3rem;
          padding-right: 2rem;
        }

        h2 {
          margin-bottom: 30px;
          img{
            @include st-sp {
              height: 16px;
              width: auto;
            }
          }
        }

        p {
          font-size: 20px;
          letter-spacing: 1px;
          line-height: 40px;

          font-style: normal;
          font-weight: 400;
          @include st-sp{
            font-size: 15px;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }
    }
  }
}

/*-----ここまでDAILY-----*/

/*-----ここからDRINK MENU-----*/
.drink-menu {
  // min-height: 1400px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  padding-top: 10rem;

  @include st-tblt {
    padding-top: 8rem;
    padding-bottom: 5rem;
    // min-height: 200rem;
  }

  @include tblt {
    // min-height: 135rem;
  }
  @include st-sp {
    padding-top: 2rem;
  }

  .drink-inner {
    max-width: 1400px;
    margin: 0 auto;

    .drink-more {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10rem;
      padding: 0 10rem;
      @include block-2col($mr: 8rem, $mt: 8rem, $tag: '> div', $tbcol: '2', $spcol: '1', $spmt: $space_cmn-sp);
      @include st-tblt {
        padding: 0;
      }

      @include tblt {
      }
      @include st-sp {
        margin-bottom: 3rem;
        padding: 0 1.5rem ;
      }

      .latte {
        max-width: 500px;
        position: relative;
        margin-top: 100px;

        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;

          @include tblt{
            top: -2.5rem;
          }

          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .latteimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt();
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
        .latte-custom {
          margin-top: 3rem;
          width: 70%;

        @include st-tblt{
          width: 100%;
        }
  
          ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            li {
              list-style: none;
              @include st-tblt{
                margin: 0 3rem 0 3rem;
              @include tblt{
                margin: 0;
              }
              }
            }
          }
        }
      }

      .coffee {
        max-width: 500px;
        position: relative;
        margin-top: 100px;

        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;

          @include tblt{
            top: -2.5rem;
          }

          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          @include st-sp {
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .cofimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt();
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }

      .tea {
        max-width: 500px;
        position: relative;
        margin-top: 100px;

        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;

          @include tblt{
            top: -2.5rem;
          }

          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .teaimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt();
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }

      .hotdog {
        max-width: 500px;
        position: relative;
        margin-top: 100px;


        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;
          
          @include tblt{
            top: -2.5rem;
          }

          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .hotdogimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt();
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }
      
      .tartine {
        max-width: 500px;
        position: relative;
        margin-top: 100px;

        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;

          @include tblt{
            top: -2.5rem;
          }


          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .tarimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt;
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height:  1.8;
            letter-spacing: 0.07em;
          }
        }
      }

      .salad {
        max-width: 500px;
        position: relative;
        margin-top: 100px;

        @include tblt {
          margin: 0 auto;
          margin-top: 6rem;
          margin-bottom: 5rem;
        }

        @include st-tblt {
          margin: 0 auto;
          margin-top: 6rem;
        }
        @include st-sp {
          text-align: center;
        }

        h2 {
          // position: absolute;
          top: 0px;
          left: -50px;
          background-color: #fff;
          padding: 10px 10px 10px 0;

          @include tblt{
            top: -2.5rem;
          }


          @include st-tblt {
            left: 0;
            padding-left: 5px;
          }
          img{
            @include st-sp {
              height: 16px;
            }
          }
        }

        .salimg {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }

        p {
          @include menu-txt();
          @include st-sp{
            font-size: 15px;
            margin-top: 1rem;
            line-height: 1.8;
            letter-spacing: 0.07em;
          }
        }
      }
    }
  }
}

/*-----ここまでDRINK MENU-----*/


/*-----ここからBAKED GOODS-----*/
.bakedgoods {
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;

  @include lt-tblt {

    height: 700px;
  }

  @include st-tblt {
    // height: 85rem;
  }

  .bakedgoods-inner {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;

    @include st-tblt {
      flex-direction: column-reverse;
    }

    .bakeslider {
      position: relative;
      // z-index: -1;
      width: 50%;

      @include st-tblt {
        width: 100%;
      }

      .bakes-slidermain {
        li {
          list-style: none;
          overflow: hidden;

          img {
            height: 700px;
            vertical-align: bottom;
            object-fit: cover;
            @include st-tblt{
              height: 60rem;
              width: 100%;
            }
            @include st-sp {
              height: 35rem;
            }
          }
        }
      }
      .bakes-slidernav{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 3rem;
        width: 100%;
        padding: 0 1.75rem;
        box-sizing: border-box;
        @include st-tblt{
          bottom: 2rem;
        }
        // .slick-list{
        //   padding: 0!important;
        //   width: 100%;
        // }
        li{
          width: 25%;
          margin: 0 .75rem;
        }
        img{
          width: 100%;
        }
      }
    }

    .bakedgoods-copy {
      width: 50%;
      max-width: 300px;
      min-width: 300px;
      margin-right: 19rem;
      margin-top: 20rem;

      @include st-tblt {
        padding: 0 1rem;
        width: 100%;
        min-width: auto;
        text-align: center;
        margin: 0 auto;
        margin-top: 8rem;
        margin-bottom: 4rem;
        box-sizing: border-box;
      }

      @include tblt {
        width: 100%;
        max-width: 55rem;
        text-align: center;
        margin: 0 auto;
        margin-top: 10rem;
        margin-bottom: 4rem;
      }
      @include st-sp {
        margin-bottom: 7rem;
        max-width: 330px;
      }

      h2 {
        margin-bottom: 30px;
        img{
          @include st-sp {
            width: 248px;
          }
        }
      }

      p {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 40px;

        font-style: normal;
        font-weight: 400;
        @include st-sp{
          font-size: 15px;
          line-height: 1.8;
          letter-spacing: 0.07em;
        }
      }
    }
  }
}

/*-----ここまでBAKED GOODS-----*/


/*-----ここからJUICE-----*/
.juice {
  // height: 700px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;

  @include st-tblt {
    // height: 95rem;
  }

  .juice-inner {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;

    @include st-tblt {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    @include st-sp {
      max-width: 320px;
      padding-bottom: 5rem;
    }
    .ps{
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      margin-bottom: 2rem;
      margin-right: 5rem;
      color: #999;
      letter-spacing: 0.07em;
      @include st-tblt {
        margin-top: 5rem;
        margin-left: 1rem;
        margin-right: 0;
      }
    }
    h2 {
      z-index: 1;

      @include lt-tblt{
        
      position: absolute;
      top: 10rem;
      right: 0;
      width: 57.5%;
      text-align: center;
      }
      @include st-tblt {
        position: relative;
        right: 0;
        margin: 0 auto 30px;
        text-align: center;
      }

      img {
        @include st-tblt {
          width: 220px;
        }

        @include tblt {
        }

      }
    }

    .juice-wrap{
      
      position: relative;
    }
    .juice-box {
      display: flex;
      // flex-direction: row;
      position: relative;
      
      @include st-tblt {
        margin-bottom: 3rem;
        flex-direction: column;
      }

      .juice-box-inner {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        


        @include st-tblt {
          flex-direction: column;
          width: 100%;
        }


        > img {
          object-fit: cover;
          flex: 0 0 auto;
          @include lt-tblt{
          width: 42.5%;
          height: 700px;
          }

          @include st-tblt {
            height: 60rem;
            width: 100%;
          }
          @include st-sp{
            height: 35rem;
          }
        }
        .juice-slider-wrap{
          @include lt-tblt{
            
          width: 100%;
          }
          @include st-tblt{
            width: 100%;
          }
        }

        .juice-copy {
          display: flex;
          align-items: flex-start;
          box-sizing: border-box;
          width: 100%;
          @include lt-tblt{
            min-height: 700px;
            //padding-left: 4.5rem;
            padding-top: 20rem;
            padding-bottom: 8rem;
          }
          @include st-tblt{
            padding: 0 1rem;
            flex-direction: column;
          }
          

          img {
            max-width: 50%;
            margin :0 auto;
            @include st-tblt{
              max-width: 70%;
              display: block;
              margin: 0 auto;
            }
          }

          .txt {
            margin-top: 5rem;
            font-size: 20px;
            letter-spacing: 1px;
            line-height: 40px;
            font-style: normal;
            font-weight: 400;
            margin-right :3rem;
            width: 50%;
            @include lt-tblt{
              //max-width: 300px;
              padding-left: 3rem;
              padding-bottom: 7rem;
            }
            @include st-tblt{
              margin-top: 3rem;
              order: -1;
              width: 100%;
              margin: 0 0 3rem;
            }
            @include st-sp{
              font-size: 15px;
              line-height: 1.8;
              letter-spacing: 0.07em;
            }
          }

          h3 {
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 40px;

            font-style: normal;
            font-weight: 400;
            margin-bottom: 1rem;
            @include st-sp {
              line-height: 1.8;
              font-size: 12px;
            }

            span {
              font-size: 25px;
              font-family: urw-din, sans-serif;
              font-weight: 500;
              font-style: normal;
            }
          }
        }
      }

      .juice-copy01 .txt{
        background: url(../image/number01.svg) bottom 8rem right no-repeat;
        background-size: 18.4rem auto;
        @include st-tblt{
          background-size: 15rem auto;
          background-position: center right;
        }
      }
      .juice-copy02 .txt{
        background: url(../image/number02.svg) bottom 8rem right no-repeat;
        background-size: 20.6rem auto;
        @include st-tblt{
          background-size: 16rem auto;
          background-position: center right;
        }
      }
      .juice-copy03 .txt{
        background: url(../image/number03.svg) bottom 8rem right no-repeat;
        background-size: 18rem auto;
        @include st-tblt{
          background-size: 15rem auto;
          background-position: center right;
        }
      }
    }

    .slider-btn {
      display: flex;
      @include lt-tblt{
        position: absolute;
        bottom: 12rem;
        right: 10rem;
      }
      @include st-tblt{
        justify-content: flex-end;
        width: calc(100% - 2rem);

      }
      
      .rightbtn,.leftbtn{
        width: 30px;
        height: 30px;
      }
      .rightbtn{
        background: url(../image/btn-r.svg) center center no-repeat;
        background-size: contain;
      }

      .leftbtn {
        background: url(../image/btn-l.svg) center center no-repeat;
        background-size: contain;
        margin-right: 2.8rem;
        @include st-tblt{
          margin-right: 1.5rem;
        }
      }
    }
  }
}

/*-----ここまでJUICE-----*/

/*-----ここからMENU-LIST-----*/
.menu-list{
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  position: relative;
  a {
    display: block;
    max-width: 100%;
    background-color: #c3593f;
    text-align: center;
    margin: 0 auto;
    height: 100px;
    color: #000;
    text-decoration: none;
    transition:$transition;
    &:hover{
      background: #c3984f;
      opacity: 1;
    }
    @include st-tblt {

      width: 100%;
    }

    .menulistcopy {
      padding-top: 3rem;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 40px;

      font-style: normal;
      font-weight: bold;
      i {
        display: inline-block;
        font-size: 15px;
        margin: 0 10px 3px 10px;
      }
    }
  }
}



/*-----ここからSPEND-----*/
.spend {
  // height: 2100px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;

  @include st-tblt {
    // height: 180rem;
  }

  @include tblt {
    // height: 190rem;
  }

  .spend-slider{
    max-width: 100%;
    overflow: hidden;
  }
  .spend-inner {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;

    h2 {
      position: absolute;
      z-index: 2;
      padding: 5rem;
      background-color: #fff;

      @include lt-tblt{
      top: 8rem;
      left: 10rem;
      }
      @include st-tblt {
        position: relative;
        // left: 2rem;
        // top: 8rem;
        padding: 4rem 2rem;
      }

      @include tblt {
        // left: 4rem;
      }

      img {
        @include st-tblt {
          height: 8rem;
          width: auto;
        }

        @include tblt {
          height: 10rem;
        }
        @include st-sp {
          height: 65px;
        }
      }
    }

    .spend-block1 {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      margin-bottom: 20rem;

      @include st-tblt {
        flex-direction: column;
        margin-bottom: 10rem;
      }

      @include st-sp {
        margin-bottom: 3rem;
      }

      @include tblt {
      }

      .spend-slider {
        .spendimage {
          width: 1000px;
          @include lt-tblt{
            
          margin-top: 8rem;
          margin-right: 10rem;
          }

          @include tblt {
            width: 80%;
            position: relative;
            // padding-top: 20rem;
            margin: 0 auto;
            z-index: 1;
          }
          @include st-tblt {
            width: 100%;
            // position: relative;
            // z-index: -1;
            margin-top: 0;
          }


          img {
            width: 100%;
            height: 550px;
            object-fit: cover;

            @include st-tblt {
              height: 20rem;
            }

            @include tblt {
              height: 30rem;
            }
          }
        }

        .spend-copy1 {
          width: 50rem;
          font-size: 20px;
          letter-spacing: 1px;
          line-height: 40px;

          font-style: normal;
          font-weight: 400;
          background-color: #fff;
          padding: 4rem 5rem;
          margin-left: 45rem;
          margin-top: -17rem;
          position: relative;

          @include st-tblt {
            padding: 2rem;
            width: 25rem;
            margin-top: -3rem;
            margin-left: auto;
          }

          @include tblt {
            padding: 2rem;
            width: 45rem;
            margin-top: -3rem;
            z-index: 2;
            margin-left: auto;
          }
          @include st-sp{
            font-size: 15px;
            line-height: 1.8;
            padding: 3.5rem;
            letter-spacing: 0.07em;
          }
          img{
            margin-bottom: 0.5rem;
            @include st-sp {
              height: 16px;
              width: auto;
          }
          }
        }
      }

      .slider-btn {
        display: flex;
        @include lt-tblt{
          position: absolute;
          
        top: 68rem;
        right: 10rem;
        }
        @include st-tblt{
          justify-content: flex-end;
          margin-right: 1rem;
        }

        @include st-tblt {
          margin-top: 2rem;
        }

        @include tblt {
          z-index: 3;
        }

        .rightbtn,.leftbtn{
          width: 30px;
          height: 30px;
        }
        .rightbtn{
          background: url(../image/btn-r.svg) center center no-repeat;
          background-size: contain;
        }
  
        .leftbtn {
          background: url(../image/btn-l.svg) center center no-repeat;
          background-size: contain;
          margin-right: 2.8rem;
          @include st-tblt{
            margin-right: 1.5rem;
          }
        }
      }

      .bg-box-y {
        position: absolute;
        left: 20rem;
        bottom: -2rem;
        width: 30rem;
        height: 30rem;
        background-color: #c3984f;
        z-index: -1;

        @include st-tblt {
          left: 0;
          bottom: 0;
          width: 15rem;
          height: 15rem;
        }

        @include tblt {
          left: 0;
          // top: 40rem;
          width: 20rem;
          height: 20rem;
          z-index: -1;
        }
        @include st-sp {
          display: none;
        }
      }
    }

    .spend-block2 {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 20rem;
      position: relative;

      @include st-tblt {
        flex-direction: column;
        margin-bottom: 4rem;
      }

      @include tblt {
        margin-bottom: 10rem;
      }

      .spendimage2 {
        width: 700px;
        margin-right: 10rem;

        @include st-tblt {
          width: 90%;
          margin-left: 0;
        }

        @include tblt {
          width: 60%;
          margin-left: auto;
        }
        @include st-sp {
          width: 100%;
        }

        img {
          height: 400px;
          width: 100%;
          object-fit: cover;

          @include st-tblt {
            height: 20rem;
          }

          @include tblt {
            height: 30rem;
          }
        }
      }

      .spend-copy2 {
        width: 50rem;
        height: 12rem;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 40px;

        font-style: normal;
        font-weight: 400;
        background-color: #fff;
        padding: 5rem;
        margin-right: -10rem;
        position: relative;

        @include st-tblt {
          padding: 2rem;
          width: 35rem;
          height: auto;
          margin-right: 0;
          margin-top: -3rem;
          width: 100%;
          box-sizing: border-box;
          max-width: 35rem;
        }

        @include tblt {
          padding: 2rem;
          width: 50rem;
          margin-top: -3rem;
          z-index: 2;
        }
        @include st-sp{
          font-size: 15px;
          line-height: 1.8;
          width: 30rem;
          padding: 3.5rem;
          letter-spacing: 0.07em;
        }
        img{
          margin-bottom: 0.5rem;
          @include st-sp {
            height: 16px;
            width: auto;
        }
        }
      }

      .bg-box-o {
        position: absolute;
        right: 0rem;
        top: 20rem;
        width: 40rem;
        height: 30rem;
        background-color: #C3593F;
        z-index: -1;

        @include st-tblt {
          width: 30rem;
          height: 20rem;
          top: 20rem;
        }
        @include st-sp {
          display: none;
        }
      }
    }

    .spend-block3 {
      padding-top: 7rem;
      display: flex;
      flex-direction: row;
      margin-bottom: 20rem;
      position: relative;

      @include st-tblt {
        flex-direction: column;
        margin-bottom: 6rem;
        padding-top: 0;
      }

      .spendimage3 {
        width: 700px;
        position: relative;
        z-index: 1;
        margin-left: 29rem;

        @include st-tblt {
          width: 90%;
          margin-left: 0;
          margin: 0 auto;
        }

        @include tblt {
          width: 60%;
          margin-left: auto;
          margin-top: 5rem;
        }
        @include st-sp {
          width: 100%;
        }

        img {
          height: 400px;
          width: 100%;
          object-fit: cover;

          @include st-tblt {
            height: 20rem;
          }

          @include tblt {
            height: 30rem;
          }
        }
      }

      .spend-copy3 {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 40px;

        font-style: normal;
        font-weight: 400;
        background-color: #fff;
        padding: 5rem;
        position: relative;
        margin-top: 26rem;
        z-index: 1;
        @include lt-tblt{
          width: 50rem;
          height: 12rem;

        }

        @include st-tblt {
          padding: 2rem;
          width: 25rem;
          margin-top: -3rem;
          margin-left: auto;
        }

        @include lt-tblt {
          margin-left: -30rem;
        }

        @include tblt {
          padding: 2rem;
          width: 50rem;
          margin-top: -3rem;
          z-index: 2;
          margin-left: auto;
          text-align: center;
        }
        @include st-sp{
          font-size: 15px;
          line-height: 1.8;
          padding: 3.5rem;
          letter-spacing: 0.07em;
        }
        img{
          margin-bottom: 0.5rem;
          @include st-sp {
            height: 16px;
            width: auto;
        }
        }

      }

      .spendimage4 {
        width: 400px;
        left: 10rem;
        top: -25rem;
        position: absolute;
        z-index: 0;

        @include st-tblt {
          display: none;
        }

        @include tblt {
          display: flex;
          width: 25rem;
          left: 5rem;
          top: -3rem;
        }

        img {
          height: 400px;
          width: 100%;
          object-fit: cover;

          @include tblt {
            height: 25rem;
          }
        }
      }

      .bg-box-g {
        position: absolute;
        left: 0rem;
        top: -3rem;
        width: 40rem;
        height: 30rem;
        background-color: #3c5f4a;
        z-index: -1;

        @include st-tblt {
          top: 11rem;
          width: 15rem;
          height: 15rem;
        }

        @include tblt {
          top: 11rem;
          width: 20rem;
          height: 20rem;
        }
        @include st-sp {
          display: none;
        }
      }
    }
    .spend-block4 {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 20rem;
      position: relative;

      @include st-tblt {
        flex-direction: column;
        margin-bottom: 4rem;
      }

      @include tblt {
        margin-bottom: 10rem;
      }

      .spendimage4 {
        width: 800px;
        margin-right: 10rem;

        @include st-tblt {
          width: 90%;
          margin-left: 0;
        }

        @include tblt {
          width: 60%;
          margin-left: auto;
        }

        @include st-sp {
          width: 100%;
        }

        img {
          height: 450px;
          width: 100%;
          object-fit: cover;

          @include st-tblt {
            height: 20rem;
          }

          @include tblt {
            height: 30rem;
          }
        }
      }

      .spend-copy4 {
        width: 50rem;
        height: 12rem;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 40px;
        
        font-style: normal;
        font-weight: 400;
        background-color: #fff;
        padding: 5rem;
        margin-right: -15rem;
        margin-top: 30rem;
        position: relative;

        @include st-tblt {
          padding: 2rem;
          width: 35rem;
          height: auto;
          margin-right: 0;
          margin-top: -3rem;
          width: 100%;
          box-sizing: border-box;
          max-width: 35rem;
        }

        @include tblt {
          padding: 2rem;
          width: 50rem;
          margin-top: -3rem;
          z-index: 2;
        }

        @include st-sp{
          font-size: 15px;
          line-height: 1.8;
          width: 30rem;
          padding: 3.5rem;
          letter-spacing: 0.07em;
        }
        img{
          margin-bottom: 0.5rem;
          @include st-sp {
            height: 16px;
            width: auto;
          }
        }
      }

      .bg-box-b {
        position: absolute;
        right: 0rem;
        top: -10rem;
        width: 40rem;
        height: 30rem;
        background-color: #00507E;
        z-index: -1;

        @include st-tblt {
          width: 30rem;
          height: 20rem;
          top: 5rem;
        }
        @include st-sp {
          display: none;
        }
      }
    }
  }
}

/*-----ここまでSPEND-----*/


/*-----ここからINSTA-----*/
.insta {
  // min-height: 700px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  border-bottom: solid 10px #000;
  // margin-bottom: -5rem;
  padding-bottom: 5rem;

  @include st-tblt {
    // height: 52rem;
    padding-bottom: 8rem;
  }

  @include tblt {
    // height: 65rem;
  }

  .insta-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 90px;
    position: relative;
    @include st-sp {
      padding-top: 5rem;
    }

    h2 {
      margin-bottom: 2.5rem;
      width: 240px;
      margin-bottom: 25px;

      @include st-tblt {
        text-align: center;
        margin: 0 auto;
      }
      @include st-sp {
        width: 204px;
        margin-bottom: 4.5rem;
      }
    }

    .insta-more {
      font-size: 13px;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-style: normal;
      width: 10rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      @include lt-tblt {
        position: absolute;
        right: 0;
        top: 10rem;
      }

      @include st-tblt {
        margin: 0 2rem 0 auto;
      }

      a {
        color: #000;
        text-decoration: none;
        display: block;
        width: 100%;
        &:hover{
          opacity: 1;
          color: #3c5f4a;
        }
      }

      &::after {
        content: "";
        background: url(../image/more.svg) no-repeat;
        display: block;
        width: 9px;
        height: 9px;
      }
    }

    #sb_instagram {
      @include st-tblt {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      #sbi_images {
        display: flex;
        flex-wrap: wrap;

        .sbi_item {
          @include lt-tblt {
            margin-right: 5rem!important;
            margin-bottom: 5rem!important;
            max-width: calc(20% - (20rem / 5));
          }
          @include tblt{
            max-width: 20%;

          }
          @include st-sp {
            width: 50%;
          }

          &:nth-of-type(5n) {
            @include lt-sp {
              margin-right: 0!important;
            }
          }
        }

        img {
          max-width: 100%;
          vertical-align: bottom;
        }
      }
    }
  }
}

/*-----ここまでINSTA-----*/

/*-----ここからCOMING-----*/
.coming{
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  position: relative;
  .com-inner{
    width: 100%;
    text-align: center;
    img{
      margin: 0 auto;
      margin-bottom: 3.5rem;
    }
  }
}

/*-----ここからEVENT-----*/
.event {
  // height: 700px;
  border-left: solid 10px #000;
  border-right: solid 10px #000;
  padding:12rem 1rem 10rem;
  @include st-tblt{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .event-inner {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    h2 {
      margin: 0 auto 10rem;
      width: 125px;
      @include st-tblt{
        margin-bottom: 6rem;
      }
    }
  }
  .event-more{
    
      font-size: 13px;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-style: normal;
      width: 10rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 0 auto;

      @include lt-tblt {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      @include st-tblt {
        margin: 3rem 0 0 auto;
      }

      a {
        color: #000;
        text-decoration: none;
        display: block;
        width: 100%;
        &:hover{
          color: #3c5f4a;
          opacity: 1;
        }
      }

      &::after {
        content: "";
        background: url(../image/more.svg) no-repeat;
        display: block;
        width: 9px;
        height: 9px;
      }
  }

.event-main{
  
  li{
    margin-bottom: 10rem;
    @include st-tblt{
      margin-bottom: 4.5rem;
    }
  }
  a{
    display: flex;
    align-items: center;
    width: 100%;
    @include hover01;
    @include st-tblt{
      flex-direction: column;
    }
  }

  .img{
    @include lt-tblt{
      
    width: 44.5%;
    }
    @include st-tblt{
      width: 100%;
      margin-bottom: 2rem;
    }
    img{
      width: 100%;
    }
  }
  .cont{
    @include lt-tblt{
      
    width: 55.5%;
    padding-left: 10rem;
    }

    @include st-tblt{
      width: 100%;
    }
    .day{
      @include font_Din;
      border-left: 3px solid #19507C;
      font-size: 2.5rem;
      text-align: center;
      display: inline-block;
      padding-left: 2rem;
      margin-bottom: 3.5rem;
      @include st-tblt{
        margin-bottom: 1rem;
      }
      strong{
        padding-top: .5rem;
        display: block;
        font-size: 3.5rem;
      }
    }
    .txt{
      width: 100%;
      font-size: 2.5rem;
      line-height: 2;
      margin-bottom:1.5rem;
    }
    .cat{
      margin-top: 3rem;
      span{
        @include font_Din;
        color: #00507C;
        font-size: 1.3rem;
        font-weight: 400;
        padding-right: 1.5rem;
      }
    }
    .subttl{
      font-size: 1.5rem;
      line-height: 1.5;
      @include st-tblt{
        line-height: 1.75;
      }
    }
  }
}
}

/*-----ここまでEVENT-----*/


/*-----ここからfooter-----*/
.foot {
  border: solid 10px #000;

  @include lt-tblt{
    
  height: 700px;
  }
  @include st-tblt {
    // height: 121rem;
  }

  .footer-inner {
    display: flex;
    flex-wrap: wrap;

    @include st-tblt {
      width: 100%;
      flex-direction: column-reverse;
    }

    .footer-menu {
      background-color: #c3984f;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include lt-tblt{
        margin-right: -10px;
      height: 700px;
      }

      @include st-tblt {
        width: 100%;
        padding: 3rem 2rem;
        box-sizing: border-box;
      }

      .footlogo {
        width: 100px;
        margin-right: 4rem;

        @include st-tblt {
          margin-right: 3rem;
        }

      }

      .sitemap {
        // width: 140px;
        margin-left: 4rem;

        @include st-tblt {
          margin-left: 0;
          flex: 0 0 auto;
        }

        .menucontents {
          list-style: none;

          li {
            margin-bottom: 25px;
          }

          li:last-child {
            margin-top: 50px;
          }
        }

        .snsicon {
          list-style: none;

          li {
            display: inline-block;
            margin-right: 10px;
          }
          
          i{
            font-size: 18px;
          }
        }
      }
    }

    .info-inner {
      width: 50%;
      background-color: #000;
      border-left: solid 10px #000;
      border-bottom: solid 10px #000;

      @include lt-tblt{
        
      height: 700px;
      }
      @include st-tblt {
        border-left: none;
        width: 100%;
        box-sizing: border-box;
      }

      .footer-info {
        background-color: #3c5f4a;
        width: 100%;
        height: 345px;
        border-bottom: solid 5px #000;
        display: flex;
        justify-content: center;
        align-items: center;

        @include st-tblt {
          flex-direction: column;
        }

        .infologo {
          margin-right: 2.5rem;

          @include st-tblt {
            margin: 0 auto;
            margin-bottom: 3rem;
          }
        }

        .access {
          margin-left: 3rem;
          margin-top: 2rem;

          @include st-tblt {
            margin: 0 auto;
          }

          .accesslist {
            li {
              list-style: none;
              font-size: 13px;
              color: #000;
              letter-spacing: 1px;
              line-height: 30px;

              font-style: normal;
              font-weight: 400;
              margin-bottom: 15px;
              display: flex;
            }

            .access-clock::before {
              content: "";
              background: url(../image/clockicon.svg) no-repeat;
              display: inline-block;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin: 0 10px 3px 0;
            }

            .access-tel::before {
              content: "";
              background: url(../image/telicon.svg) no-repeat;
              display: inline-block;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin: 0 10px 3px 0;
            }

            .access-pin::before {
              content: "";
              background: url(../image/pinicon.svg) no-repeat;
              display: inline-block;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin: 0 10px 3px 0;
            }
          }
        }
      }

      .footer-map {
        background-color: #00507e;
        width: 100%;
        
        border-top: solid 5px #000;
        position: relative;
        overflow: hidden;
        @include lt-tblt{
          
        height: 345px;
        }
        @include st-tblt{
          height: 345px;
        }

        .mapbg {
          position: absolute;
          width: 100%;
          height: 355px;
          object-fit: cover;
          @include st-tblt{
            height: 100%;
          }
        }

        a{

          position: absolute;
          @include lt-tblt{
            
          top: 220px;
          right: 25px;
          }
          @include st-tblt{
            left: 1rem;
            top: 2rem;
          }
          img {
            @include st-tblt{
              width: 80px;
            }
          }
        }
      }
    }
  }
}

/*-----ここまでfooter-----*/

.slider-btn p{
  @include hover01;
  &:hover{
    cursor: pointer;
  }
}

@import "lower";


.head{
  .headnav{
    transition: cubic-bezier(.27,.51,.71,1.03) .5s;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    width: calc(50% + 15px);
    height: 100%;
    background: #c3984f;
    border: 10px solid #000;
    padding: 3rem;
    box-sizing: border-box;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    pointer-events: auto;
    @include st-tblt{
      min-width: 250px;
    }
    &-inner{
      li{
        margin-bottom: 1rem;
      }
      a{
        display: block;
        padding: 1rem 0;
      }
      .snsicon{
        margin-top: 3rem;
        li {
          display: inline-block;
          margin-right: 10px;
        }
          
        i{
          font-size: 18px;

        }
      }
    }
  }
}
.head.is-open{
  .headnav{
    transition: cubic-bezier(.27,.51,.71,1.03) .5s;
    opacity: 1;
    transform: translate3d(0, 0, 0);

  }
}