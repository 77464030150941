@charset "UTF-8";
// @import "reset";

// ************************* //
// SIZES
// ************************* //

$tblt_breakpoint : 1024px;
$tbmin_breakpoint : 768px;
$sp_breakpoint : 641px;

//決め打ちのPC横幅
$pc_outerWidth:1400px;
$pc_width : 1200px;

//小さめコンテンツのmax幅
$cont-max-width-s : 920px;


//コンテンツ内で縮めたい時
// @mixin cmn-min-cont {
//   max-width: calc(100% - 6rem);
//   @include st-sp {
//     max-width: calc(100% - 3rem);
//   }
// }


// @mixin cmn-max-outerWidth{
// width: 100%;
// margin: 0 auto;
// @include lt-tblt {
//   max-width: calc(#{$pc_outerWidth} + (#{$lt-tblt_outer-row} * 2));
//   padding-left: $lt-tblt_outer-row;
//   padding-right: $lt-tblt_outer-row;
// }
// @include st-tblt {
//   max-width: calc(#{$pc_outerWidth} + (#{$lt-tblt_outer-row} * 2));
//   padding-left: $lt-tblt_outer-row;
//   padding-right: $lt-tblt_outer-row;
// }
// }

@mixin cmn-max-width {
  @include lt-tblt {
    width: calc(100% - (#{$lt-tblt_space-row} * 2));
    margin-left: $lt-tblt_space-row;
    margin-right: $lt-tblt_space-row;
  }

  @include st-tblt {
    width: calc(100% - (#{$st-tblt_space-row} * 2));
    margin-left: $st-tblt_space-row;
    margin-right: $st-tblt_space-row;
  }

  @include st-sp {
    width: calc(100% - (#{$st-sp_space-row} * 2));
    margin-left: $st-sp_space-row;
    margin-right: $st-sp_space-row;

  }
}

@mixin inner-margin {
  margin-left: 100px;
  margin-right: 100px;

  @include st-tblt {
    margin-left: 50px;
    margin-right: 50px;
  }

  @include st-sp {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

// ************************* //
// BREAK POINT
// ************************* //

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($bm-min, $bp-max) {
  @media screen and (min-width: $bm-min) and (max-width: $bp-max) {
    @content;
  }
}

/* MEDIA QUERY */

@mixin lt-sp {
  @include min-screen(#{ $sp_breakpoint + 1px }) {
    @content;
  }
}

@mixin st-sp {
  @include max-screen(#{ $sp_breakpoint }) {
    @content;
  }
}

@mixin lt-tbmin {
  @include min-screen(#{ $tbmin_breakpoint + 1px }) {
    @content;
  }
}

@mixin st-tbmin {
  @include max-screen(#{ $tbmin_breakpoint }) {
    @content;
  }
}

@mixin lt-tblt {
  @include min-screen(#{ $tblt_breakpoint + 1px }) {
    @content;
  }
}

@mixin st-tblt {
  @include max-screen(#{ $tblt_breakpoint }) {
    @content;
  }
}


@mixin tblt {
  @include screen(#{ $sp_breakpoint + 1px }, #{ $tblt_breakpoint }) {
    @content;
  }
}

@mixin st-notepc {
  @include max-screen(#{ $pc_width }) {
    @content;
  }
}

.lt-sp {
  @include st-sp {
    display: none !important;
  }

  ;
}

.st-sp {
  @include lt-sp {
    display: none !important;
  }

  ;
}

.lt-tblt {
  @include st-tblt {
    display: none !important;
  }

  ;
}

.st-tblt {
  @include lt-tblt {
    display: none !important;
  }

  ;
}

// ************************* //
// COLORS
// ************************* //

// 背景色
$white : #FFF;
$gray : #ccc;
$black : #404040;

/* Text Color */
$txt_color : $black;


// ************************* //
// FONTS
// ************************* //

$sans:a-otf-gothic-bbb-pr6n,"游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
$din:urw-din,"游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
$serif:'Noto Serif JP',"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

@mixin font_Sans {
  font-family: $sans;
  font-weight: 400; //400 or 500 or 700
  font-style: normal;
}

@mixin font_Serif {
  font-family: $serif;
  font-weight: 700;
  font-style: normal;
}
@mixin font_Din {
  font-family: $din;
  font-weight: 500;
  font-style: normal;
}

@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* FONT SIZE */
$fz_pc: 10px;

$fz_xl : 3rem;
$fz_l : 2rem;
$fz_ml: 1.8rem;
$fz_m : 1.6rem;
$fz_sm : 1.5rem;
$fz_s : 1.2rem;

$fz_cmn : 1.4rem;

//見出し文字
$fz_ttl_l : 11.5rem;
$fz_ttl_m : 7rem;
$fz_ttl_s : 4.5rem;
//見出し文字
$fz_ttl_l-sp : 11rem;
$fz_ttl_m-sp : 6.5rem;
$fz_ttl_s-sp : 4rem;


//letter-spacing
$ls_350: 0.35em;
$ls_200: 0.2em;
$ls_150: 0.15em;
$ls_100: 0.10em;
$ls_75: 0.075em;
$ls_20: 0.02em;
$ls_0: 0;

$ls_cmn:$ls_0;

//letter-spacing(限定的)
$ls_spot_360: 0.36em;


//line-height
$lh_2-5:2.5;
$lh_2-2:2.2;
$lh_1-85:1.85;
$lh_1-75:1.75;
$lh_1-7:1.7;
$lh_1-65:1.65;
$lh_1: 1;


//font-weight
$fw_exlight: 200;
$fw_light: 300;
$fw_medium: 500;
$fw_semibold: 600;
$fw_bold: 700;

$fw_cmn: 400;




/* FONT SET */

@mixin default_font {
  font-family: $sans;
  @include antialiased;
  // letter-spacing: $ls_75;

  @include lt-tblt {
    font-size: 62.5%;
  }

  @include st-tblt {
    font-size: calc(10px + #{ ( ( 14px - 10px ) / $sp_breakpoint ) * 50 + 'vw' });
  }

  @include st-sp {
    // font-size: 2vw;
  }

  @media screen and (max-width:320px) {
    font-size: 2.35vw;
  }
}


/* xd文字スタイルに入ってるセットを設定 */

@mixin sub_font {
  font-size: $fz_l;
  letter-spacing: $ls_350;
  line-height: $lh_1-2;
}

@mixin large_font {
  font-size: $fz_l;
  letter-spacing: $ls_350;
  line-height: $lh_1-2;
}


// ************************* //
// MARGIN・SPACE
// ************************* //


//流動マージン
@mixin fluid_space($s:'margin', $p:'bottom', $v:$boxSpace_xl) {
  @include lt-sp {
    #{$s}-#{$p}: $v;
  }

  @include st-tblt {
    #{$s}-#{$p}: calc(#{$v} * .85);
  }

  @include st-sp {
    #{$s}-#{$p}: calc(#{$v} * .5);
  }

  // @include lt-pc {
  //   #{$s}-#{$p}: calc(#{$v} * 1.25);
  // }
}

//SP上下
@mixin sp-inner_space($vt:$space_l, $vb:$space_m) {
  @include st-sp {
    padding: $vt $vb;
  }
}


//外箱(art/sect etc)用
//105→100統一
$boxSpace_xxxl:18rem;
$boxSpace_xxl:15rem;
$boxSpace_xl:10rem;
$boxSpace_l:9rem;
$boxSpace_ml:8rem;
$boxSpace_m:7rem;
$boxSpace_s:5rem;


//内箱用
$space_xxxl:5rem;
$space_xxl:4.5rem;
$space_xl:4rem;
$space_ll:3.5rem;
$space_l:3rem;
$space_ml:2.5rem;
$space_m:2rem;
$space_sm:1.5rem;
$space_s:1rem;
$space_ss:.5rem;

//基本の左右マージン
$space_cmn-lr: $space_m;
$space_cmn-sp: $space_s;

//タブレット以下基本下マージン
$space_cmn-tab : 3rem;

// ************************* //
// FLEX
// ************************* //

//flexbox

@mixin flex {
  display: flex;
}

@mixin i-flex {
  display: inline-flex;
}

@mixin fl-space($v:space-between) {
  justify-content: $v;
}

@mixin fl-wrap($v:wrap) {
  flex-wrap: $v;
}

@mixin fl-item($v:center) {
  align-items: $v;
}

@mixin fl-direct($v:column) {
  flex-direction: $v;
}

// ************************* //
// FLEX BLOCK
// ************************* //

//block
@mixin block-2col($mr:$space_cmn-lr, $mt:$space_l, $tag:li, $tbcol:'2', $spcol:'1', $spmt:$space_cmn-sp) {
  @include flex;
  @include fl-wrap($v: wrap);

  @include st-sp {
    @include fl-direct($v: column);
  }

  @if $tbcol=='2' {
    #{$tag} {
      @include lt-sp {
        width: calc(50% - (#{$mr} / 2));

        &:nth-of-type(odd) {
          margin-right: $mr;
        }

        &:nth-of-type(n+3) {
          margin-top: $mt;
        }
      }
    }
  }

  @if $tbcol=='1' {
    #{$tag} {
      @include lt-tblt {
        width: calc(50% - (#{$mr} / 2));

        &:nth-of-type(odd) {
          margin-right: $mr;
        }

        &:nth-of-type(n+3) {
          margin-top: $mt;
        }
      }

    }
  }

  @if $spcol=='1' {
    @include st-sp {
      @include fl-direct($v: column);
    }

    #{$tag} {
      @include st-sp {
        width: 100%;

        &:nth-of-type(odd) {
          margin-right: 0;
        }

        &:not(:first-of-type) {
          margin-top: $spmt;
        }
      }
    }
  }

  @if $spcol=='2' {
    @include st-sp {
      @include fl-direct($v: row);
    }

    #{$tag} {
      @include st-sp {
        width: calc(50% - (#{$space_cmn-sp} / 2));

        &:nth-of-type(odd) {
          margin-right: 1rem;
        }

        &:nth-of-type(n+3) {
          margin-top: $spmt;
        }
      }
    }
  }
}

@mixin block-3col($mr:$space_cmn-lr, $mt:$space_l, $tag:li, $spcol:'1', $spmt:$space_cmn-sp) {
  @include flex;
  @include fl-wrap($v: wrap);

  #{$tag} {
    @include lt-tblt {
      width: calc(33.333% - ((#{$mr} * 2) / 3));

      &:not(:nth-of-type(3n)) {
        margin-right: $mr;
      }

      &:nth-of-type(n+4) {
        margin-top: $mt;
      }
    }

    @include st-tblt {
      width: calc(50% - (#{$mr} / 2));

      &:nth-of-type(odd) {
        margin-right: $mr;
      }

      &:nth-of-type(n+3) {
        margin-top: $mt;
      }
    }
  }

  @if $spcol=='1' {
    @include st-sp {
      @include fl-direct($v: column);
    }

    #{$tag} {
      @include st-sp {
        width: 100%;

        &:nth-of-type(odd) {
          margin-right: 0;
        }

        &:not(:first-of-type) {
          margin-top: $spmt;
        }
      }
    }
  }

  @if $spcol=='2' {
    @include st-sp {
      @include fl-direct($v: row);
    }

    #{$tag} {
      @include st-sp {
        width: calc(50% - (#{$space_cmn-sp} / 2));

        &:nth-of-type(odd) {
          margin-right: 1rem;
        }

        &:nth-of-type(n+3) {
          margin-top: $spmt;
        }
      }
    }
  }
}

@mixin block-4col($mr:$space_cmn-lr, $mt:$space_l, $tag:li, $spcol:'1', $spmt:$space_cmn-sp) {
  @include flex;
  @include fl-wrap($v: wrap);

  #{$tag} {
    @include lt-tblt {
      width: calc(25% - ((#{$mr} * 3) / 4));

      &:not(:nth-of-type(4n)) {
        margin-right: $mr;
      }

      &:nth-of-type(n+5) {
        margin-top: $mt;
      }
    }

    @include st-tblt {
      width: calc(50% - (#{$mr} / 2));

      &:nth-of-type(odd) {
        margin-right: $mr;
      }

      &:nth-of-type(n+3) {
        margin-top: $mt;
      }
    }
  }

  @if $spcol=='1' {
    #{$tag} {
      @include st-sp {
        width: 100%;
        @include fl-direct($v: column);

        &:nth-of-type(odd) {
          margin-right: 0;
        }

        &:not(:first-of-type) {
          margin-top: $spmt;
        }
      }
    }
  }

  @if $spcol=='2' {
    #{$tag} {
      @include st-sp {
        width: calc(50% - (#{$space_cmn-sp} / 2));

        &:nth-of-type(odd) {
          margin-right: $space_cmn-sp;
        }

        &:nth-of-type(n+3) {
          margin-top: $spmt;
        }
      }
    }
  }
}



// ************************* //
// HOVER
// ************************* //

$transition : .3s;
$opacity : .6;
$ease: ease;

@mixin hover01 {
  transition: opacity ease $transition;

  &:hover {
    opacity: $opacity;
  }
}

@mixin hover02 {
  figure img{

  transition: ease $transition;
  }

  &:hover figure{
    img{
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}


/*******************************

   SINGLE

*******************************/

@mixin tategaki {
  writing-mode: vertical-rl;
}

@mixin block-a {
  display: block;
  width: 100%;
  height: 100%;
}

@mixin txt-mask {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin kerning {
  font-feature-settings: "palt"1;
}

@mixin clear{
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin trim-size($v:100%){
  &::after{
    content: "";
    display: block;
    padding-top: $v;
  }
}

@mixin trim($fix:'row'){
  position: relative;
  width: 100%;
  overflow: hidden;
  background: $black;
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @if $fix=='row'{
    img {
      width: 101%;
      height: auto;
      max-width: 101%;
    }

  }
  @if $fix=='col'{
    img {
      width: 101%;
      height: auto;
      width: auto;
      height: 101%;
      max-width: initial;
      max-width: none;
    }
  }
}